import { Controller } from "@hotwired/stimulus"

export default class PreviewController extends Controller {
    connect() {
        redgrasp.lq_explanation_trigger()
        redgrasp.comment_trigger()
        redgrasp.moreOptions()
        redgrasp.optionScroll()

        const more_options = document.getElementById('more-options');
        const lqe_div = document.getElementById('lq-explanation');
        if (more_options !== undefined && more_options !== null && lqe_div !== undefined && lqe_div !== null) {
            more_options.style.marginBottom = '31px'
        }

        const answer_options = document.getElementsByClassName('q-answer-options');
        if (answer_options.length > 0 && lqe_div !== undefined && lqe_div !== null) {
            const old_height = answer_options[0].style.height;
            answer_options[0].style.height = `${parseInt(old_height) - 31}px`;
        }

        if (answer_options.length > 0 && more_options !== undefined && more_options !== null) {
            more_options.onclick = (_) => {
                answer_options[0].scrollTop = parseInt(answer_options[0].style.height);
            }
        }
    }
}
