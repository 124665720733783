import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["year"];
  static values = {
    url: String,
    reportId: String,
    reportType: String,
    reportFormat: String,
    resources: String,
  };

  download(event) {
    event.preventDefault();
    var currentUrl = window.location.href;
    var parsedUrl = currentUrl.split("?");
    var url = parsedUrl[0].replace(/\/$/, "");
    url = url.concat("/" + this.data.get("url"));
    if (parsedUrl.length > 1) {
      url += "?" + parsedUrl[1];
      if (this.hasYearTarget) {
        if (document.querySelector("select#year").hasAttribute("multiple")) {
          url += `&year=${[...document.querySelector("select#year").selectedOptions].map((option) => option.value)}`;
        } else {
          url += `&year=${this.yearTarget.value} `;
        }
      }
    } else {
      if (this.hasYearTarget) {
        if (document.querySelector("select#year").hasAttribute("multiple")) {
          url += `?year=${[...document.querySelector("select#year").selectedOptions].map((option) => option.value)}`;
        } else {
          url += `?year=${this.yearTarget.value} `;
        }
      }
    }
    window.location.href = url;
  }

  download_report(event) {
    event.preventDefault();
    var period = window.location.href.split("/").pop();
    var url = window.location.href.replace(/\/\d+-\d+-\d+$/, "");
    url = url.concat("/" + this.data.get("url") + "/" + period);
    window.location.href = url;
  }

  request_report(event) {
    let that = this;
    event.preventDefault();
    var url = `${window.location.protocol}//${window.location.host}`;
    url = url.concat(
      this.urlValue +
        "?report_type=" +
        this.reportTypeValue +
        "&report_format=" +
        this.reportFormatValue +
        "&resources=" +
        this.resourcesValue,
    );
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        that.reportIdValue = data.report_id;
        window.report_id = data.report_id;
        that.get_report();
      });
  }

  get_report() {
    let that = this;
    var url = `${window.location.protocol}//${window.location.host}`;
    url = url.concat("/report/status/" + window.report_id);
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == "unavailable") {
          window.reportDownloadTimeout = setTimeout(that.get_report, 1000);
        } else {
          window.clearTimeout(window.reportDownloadTimeout);
          var url = `${window.location.protocol}//${window.location.host}`;
          url = url.concat("/report/download/" + window.report_id);
          window.report_id = null;
          window.location.href = url;
        }
      });
  }

  download_answers(event) {
    event.preventDefault();
    event.stopPropagation();
    var url = this.urlValue;
    window.location.href = url;
    event.target.closest(".btn-group").classList.remove("open");
  }

  disconnect() {
    window.clearTimeout(window.reportDownloadTimeout);
  }
}
