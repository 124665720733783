import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "template"]

  change(event) {
    if (this.templateTarget.hasAttribute('data-restricted-schedule')) {
      const element = event.target;
      let button = element.querySelector('input');
      const id = button.getAttribute('id');
      if (button.checked === false) {
        document.querySelectorAll('[name="campaign[template][]"]').forEach((item, i) => {
          if (item != button) {
            item.checked = false;
            item.parentNode.classList.remove('active');
          }
        });
      }
    }
  }
}
