import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let that = this;
    window.windowHeight = window.innerHeight;
    window.windowWidth = window.innerWidth;
    this.resize();

    window.addEventListener('resize', function() {
      let ua = window.navigator.userAgent;
      let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
      if (iOS || navigator.maxTouchPoints > 0) {
        if (window.innerWidth != window.windowWidth) {
          window.windowWidth = window.innerWidth;
          that.resize();
        }
      } else {
        if (window.innerHeight != window.windowHeight || window.innerWidth != window.windowWidth) {
          window.windowHeight = window.innerHeight;
          window.windowWidth = window.innerWidth;
          that.resize();
        }
      }
    });
  }

  resize() {
    let width = window.innerWidth;
    let height = window.innerHeight;
    let naturalWidth = parseInt(document.querySelector('.parallax').getAttribute('data-banner-sizex'));
    let naturalHeight = parseInt(document.querySelector('.parallax').getAttribute('data-banner-sizey'));
    let requiredHeight = (width / naturalWidth) * naturalHeight;
    if (requiredHeight < height) {
      document.querySelector('.parallax').style.height = `${(height - 52)}px`;
      document.querySelector('.banner-section').style.height = `${(height - 52)}px`;
      document.querySelector('.parallax').style.backgroundSize = `cover`;
      document.querySelector('.parallax').style.backgroundPosition = `top`;
      if (document.querySelector('.zorgorganisaties') != undefined && width < height) {
        let scaleFactor = height / naturalHeight;
        let scaledWidth = naturalWidth * scaleFactor;
        let offset = -(scaledWidth * 0.45);
        document.querySelector('.parallax').style.backgroundPositionX = `${offset}px`;
        document.querySelector('.parallax').style.backgroundPositionY = `0px`;
      }
      if (document.querySelector('.onderwijsinstellingen-2') != undefined && width < height) {
        let scaleFactor = height / naturalHeight;
        let scaledWidth = naturalWidth * scaleFactor;
        let shiftFactor = 0.45;
        if (width / height > 0.9 && width / height < 1.0) {
          shiftFactor = 0.4;
        }
        // console.log("width / height: " + width + "/" + height + "= " + width/height);
        // console.log("shiftFactor: " + shiftFactor);
        let offset = -(scaledWidth * shiftFactor);
        document.querySelector('.parallax').style.backgroundPositionX = `${offset}px`;
        document.querySelector('.parallax').style.backgroundPositionY = `0px`;
      }
    } else {
      document.querySelector('.parallax').style.height = `${(height - 52)}px`;
      document.querySelector('.banner-section').style.height = `${(height - 52)}px`;
      document.querySelector('.parallax').style.backgroundSize = `cover`;
    }
    let ua = window.navigator.userAgent;
    let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    if (iOS || navigator.maxTouchPoints > 0) {
      document.querySelector('.parallax').style.backgroundAttachment = `scroll`;
    } else {
      document.querySelector('.parallax').style.backgroundAttachment = `fixed`;
    }
  }

  scroll() {
    if (document.querySelector('.parallax').style.backgroundAttachment == `fixed`) {
      let width = window.innerWidth;
      let height = window.innerHeight;
      let naturalWidth = parseInt(document.querySelector('.parallax').getAttribute('data-banner-sizex'));
      let naturalHeight = parseInt(document.querySelector('.parallax').getAttribute('data-banner-sizey'));
      let requiredHeight = (width / naturalWidth) * naturalHeight;
      if (requiredHeight < height) {
        let scrollPosition = window.scrollY;
        let adjustedTop = Math.max(0, (52 - parseInt(scrollPosition)));
        document.querySelector('.parallax').style.backgroundPositionY = `${adjustedTop}px`;
      }
    }
  }
}
