import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.check_validation();
  }

  check_validation() {
    var url = window.location.href;
    var action = url.split('/').pop();
    url = url.replace(action, this.data.get('url'));
    url += `?upload_hash=${this.data.get('upload-hash')}`;
    console.log(url);
    fetch(url)
    .then(response => response.json())
    .then(data => {
      if (data.status == 'available') {
        document.querySelector('.upload-spinner').outerHTML = data.html;
      } else {
        document.querySelector('#progress_value').innerHTML = data.progress;
        window.checkValidationTimeout = setTimeout(this.check_validation(), 1000);
      }
    });
  }

  update() {
    let importable = [];
    document.querySelectorAll('input[id^="updated_"]:checked').forEach((item, i) => {
      importable.push(item.getAttribute('value'));
    });
    importable = importable.join(' ')
    let answer = confirm(I18n.t('confirm_import'));
    if (answer) {
      document.querySelector('#update_records_form #lines').setAttribute('value', importable);
      document.querySelector('#update_records_form').submit();
    }
  }

  show_changes(event) {
    const element = event.target;

    if (element.tagName == 'TD') {
      const id = element.parentNode.getAttribute('id').match(/\d+/)[0];
      const current_data = document.querySelectorAll(`[data-record="${id}"]`);
      current_data.forEach((item, i) => {
        if (item.style.display == 'none'){
          item.style.display = 'table-row';
        } else {
          item.style.display = 'none';
        }
      });
    }
  }

  disconnect() {
    window.clearTimeout(window.checkValidationTimeout);
  }
}
