import { Controller } from "@hotwired/stimulus"

export default class PublicQuestionController extends Controller {
    get_parent(event) {
        let parent = event.target

        if (parent.tagName === 'I') {
            parent = parent.parentNode;
        }

        return parent
    }

    show_tooltip(platform) {
        const tooltip_element = document.getElementById(`${platform}-tooltip`);
        tooltip_element.style.visibility = '';

        parent.addEventListener('mouseout', function (_) {
            tooltip_element.style.visibility = 'hidden';
        })
    }

    copy(event) {
        const parent = this.get_parent(event)

        const platform = parent.id.split('-')[0];

        const content_column = Array.prototype.slice.call(parent.parentNode.children, 0)[1];
        const content = Array.prototype.slice.call(content_column.children, 0)

        let str = "";
        for (let i = 0; i < content.length; i++) {
            const current_tag = content[i]

            switch (current_tag.tagName) {
                case "DIV":
                    str += current_tag.innerHTML
                    break
                case "A":
                    str += current_tag.innerHTML
                    break
                case "BR":
                    break
                default:
                    continue
            }

            if (i !== content.length - 1) {
                str += '\n'
            }
        }

        navigator.clipboard.writeText(str)
        this.show_tooltip(platform)
    }

    connect() {
        // Expose the endpoint to the extension
        window['load_template'] = function (callback) {
            const origin = window.location.origin;
            const id = document.getElementById('replace-tag')
                .innerHTML.split('#').pop()
                .replaceAll(']', '');

            const endpoint_url = `${origin}/public_questions/${id}/template`
            fetch(endpoint_url).then(callback)
        }
    }
}
