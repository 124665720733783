import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["department", "relatedDepartment"];

  change_department() {
    let department = this.departmentTarget.value;
    let current_selection = this.relatedDepartmentTarget.value;
    this.relatedDepartmentTarget
      .querySelector("option[disabled]")
      .removeAttribute("disabled");
    this.relatedDepartmentTarget
      .querySelector("option[value='" + this.departmentTarget.value + "']")
      .setAttribute("disabled", "");
    console.log(`current_selection: ${current_selection}`);
    console.log(`department: ${department}`);
    if (current_selection == department) {
      this.relatedDepartmentTarget.value = "";
    }
  }
}
