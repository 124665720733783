import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "select" ]

  get_func_scope() {
    let scope = 0
    document.querySelectorAll('[type=checkbox][name^="campaign[func_scope]"]:checked').forEach((item, i) => {
      const value = parseInt(item.getAttribute('value'));
      scope += 2**value;
    });
    return scope;
  }

  scope_change() {
    let that = this;
    let url = window.location.href;
    let action = url.split('/').pop();
    let updated_scope = this.get_func_scope();
    url = url.replace(action, this.data.get('url'));
    url += `?scope=${updated_scope}`;
    fetch(url)
    .then(response => response.json())
    .then(data => {
      if (that.selectTarget != 'undefined') {
        that.selectTarget.innerHTML = data.html;
        console.log('that.selectTarget is defined and its innerHTML is updated');
      } else {
        console.log("that.selectTarget is undefined...");
        if (that.hasSelectTarget) {
          console.log("that.hasSelectTarget should exist");
        } else {
          console.log("that.selectTarget apparently doesn't exist");
        }
      }
    });
  }
}
