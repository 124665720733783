import { SocketUserController } from './socket_user_controller'

export class AnalyticsChartController extends SocketUserController {
  connect() {
    this.create_socket_connection('AnalyticsChartChannel', {});
    let spinners = document.querySelectorAll('.analytics-websocket-spinner');

    spinners.forEach((item, i) => {
      let panelBody = item.parentNode;
      let panel = panelBody.parentNode;
      let source = item.getAttribute('data-source');
      let url = window.location.href;
      let params = url.split('?').pop();
      if (params.length > 0) {
        url = url.split('?')[0].replace('.html', '') + `/${source}` + `?${params}`;
      } else {
        url += `/${source}`;
      }

      panel.querySelectorAll('label.btn').forEach((item, i) => {
        item.setAttribute('disabled','')
      })
      panel.querySelector('.period_filter .trigger').setAttribute('disabled','')
      panelBody.setAttribute('data-filters','disabled')

      fetch(url);
    });
  }

  on_data(data) {
    let target = data['target'];
    let html = data['html'];
    let campaign = data['campaign'];
    let values = data['values'];

    Object.keys(values).forEach((key) => {
      window[key] = values[key]
    })

    console.log("Received data...")

    if (campaign != undefined) {
      document.querySelector('[data-campaign-id="' + campaign + '"]').parentNode.innerHTML = html;
    } else {
      let panelBody = document.querySelector('[data-source="' + target + '"]').parentNode;
      let panel = panelBody.parentNode;
      document.querySelector('[data-source="' + target + '"]').parentNode.innerHTML = html;
      redgrasp.drawAsyncCharts()

      panel.querySelectorAll('label.btn').forEach((item, i) => {
        item.removeAttribute('disabled')
      })
      panel.querySelector('.period_filter .trigger').removeAttribute('disabled')
      panelBody.removeAttribute('data-filters')
    }

  }

  on_connect() {
    console.log('Connected!!');
  }
}

export default AnalyticsChartController;
