import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle", "switch"]

  connect() {
    let that = this;
    let url = window.location.href
    let action = url.split('/').pop()
    url = url.replace(action, 'content/media')
    fetch(url)
    .then(response => response.json())
    .then(data => {
      let container = document.querySelector('.table-container#table_2');
      // Reset vertical-align
      container.style.textAlign = 'left';
      container.style.top = 0;
      container.style.webkitTransform = 'translateY(0)';
      container.style.msTransform = 'translateY(0)';
      container.style.transform = 'translateY(0)';
      document.querySelector('#table_2').innerHTML = data.html;
      that.list_view();
    });
  }


  toggle() {
    // TODO: Include domains_query and media_query
    let that = this;
    let url = this.data.get('url');
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    fetch(url, {
      method: "PUT",
      body: JSON.stringify({
        status: this.toggleTarget.checked
      }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "X-CSRF-Token": token
      }
    })
    .then(function(response) {
      if (response.ok) {
        window.dispatchEvent(new CustomEvent("allMediaToggle", {detail: {status: that.toggleTarget.checked}}))
      }
    });
  }

  update(event) {
    let totalCount = this.element.querySelectorAll('[data-medium-select-domain]').length
    let selectionCount = this.element.querySelectorAll('[data-medium-select-domain] input[type="checkbox"]:checked').length
    if (selectionCount == totalCount) {
      this.toggleTarget.checked = true
    } else {
      this.toggleTarget.checked = false
    }
  }

  switch() {
    let mode = this.data.get('mode');
    if (mode == 'list') {
      this.toggleTarget.parentElement.style.visibility = 'visible';
      this.switchTarget.innerText = I18n.t('done');
      this.data.set('mode', 'edit');
      this.edit_view();
    } else {
      this.toggleTarget.parentElement.style.visibility = 'hidden';
      this.switchTarget.innerText = I18n.t('select');
      this.data.set('mode', 'list');
      this.list_view();
    }
  }

  edit_view() {
    let selected_domains = Array.from(this.selected_domains());
    document.querySelectorAll('#table_2 .checkboxRG input[type="checkbox"]').forEach((item, i) => {
      item.removeAttribute('disabled');
      let row = item.parentElement.parentElement.parentElement
      row.removeAttribute('data-shown');
      if (!row.hasAttribute('data-domain-header')) {
        if (row.getAttribute('data-level') == '0') {
          if (!window.active_media_search || (window.active_media_search && window.media_result.indexOf(parseInt(item.value)) != -1)) {
            row.style.display = 'table-row'
            row.setAttribute('data-nested-collapse-display-status-value', 'shown')
          } else {
            row.style.display = 'none'
            row.setAttribute('data-nested-collapse-display-status-value', 'hidden')
          }
        } else {
          row.style.display = 'none'
          row.setAttribute('data-nested-collapse-display-status-value', 'hidden')
        }
      } else {
        if (!window.active_media_search || (window.active_media_search && selected_domains.indexOf(row.getAttribute('data-domain-header')) != -1)) {
          row.style.display = 'table-row';
        } else {
          row.style.display = 'none'
        }
      }
    });
    document.querySelectorAll('[data-nested-collapse-target="toggle"]').forEach((item, i) => {
      item.classList.replace('fa-caret-down', 'fa-caret-right');
    });
    document.querySelector('#no_media_selected').style.display = 'none'
  }

  list_view(event) {
    let mode = this.data.get('mode');
    if (event && event.detail.mode == 'addDomain' && mode == 'edit') {
      document.querySelectorAll('#table_2 .checkboxRG input[type="checkbox"]').forEach((item, i) => {
        item.removeAttribute('disabled')
      });
      return false;
    }
    let selected_domains = this.selected_domains();
    document.querySelectorAll('#table_2 .checkboxRG input[type="checkbox"]').forEach((item, i) => {
      item.setAttribute('disabled', '');
      let row = item.parentElement.parentElement.parentElement
      row.style.display = 'none'
      row.setAttribute('data-nested-collapse-display-status-value', 'hidden')
      let domain = row.getAttribute('data-domain-id');
      let domain_header = row.getAttribute('data-domain-header')
      let level = row.getAttribute('data-level')
      if (selected_domains.has(domain) || selected_domains.has(domain_header)) {
        if (domain_header != undefined) {
          if (!window.active_media_search || (window.active_media_search && window.domains_result.indexOf(parseInt(row.getAttribute('data-domain-header'))) != -1)) {
            row.style.display = 'table-row';
          }
        } else if (item.checked && level === '0') {
          if (!window.active_media_search || (window.active_media_search && window.media_result.indexOf(parseInt(item.value)) != -1)) {
            row.style.display = 'table-row';
            row.setAttribute('data-nested-collapse-display-status-value', 'shown')
            row.setAttribute('data-shown', '')
          } else {
            row.setAttribute('data-nested-collapse-display-status-value', 'hidden')
            row.setAttribute('data-shown', '')
          }
        } else if (item.checked) {
          row.setAttribute('data-nested-collapse-display-status-value', 'hidden')
          row.setAttribute('data-shown', '')
        }
      }
    });
    this.selected_top_media().forEach((item, i) => {
      let all_tuples = this.create_tuples(item);
      let selected = all_tuples.filter(entry => entry[3]);
      let selected_ids = [];
      selected.forEach((item, i) => {
        selected_ids.push(item[0]);
      });
      let parents = [];
      selected.forEach((item, i) => {
        let parent = item[1];
        while (parent != null) {
          if (!parents.includes(parent)) {
            parents.push(parent);
          }
          parent = all_tuples.filter(entry => entry[0] === parent)[0][1];
        }
      });
      let unselected_parents = parents.filter(i => selected_ids.indexOf(i) == -1);
      selected_ids.concat(unselected_parents).forEach((item, i) => {
        let parent = document.querySelector(`[data-medium-id="${item}"]`);
        parent.setAttribute('data-shown','');
        if (parent.getAttribute('data-level') === '0' ) {
          parent.style.display = 'table-row'
          parent.setAttribute('data-nested-collapse-display-status-value', 'shown');
          const toggleIcon = parent.querySelector('.fa-caret-down');
          if (toggleIcon != null) {
            toggleIcon.classList.replace('fa-caret-down', 'fa-caret-right');
          }
        } else {
          parent.setAttribute('data-nested-collapse-display-status-value', 'hidden');
        }
      });
    });
    if (selected_domains.size > 0) {
      document.querySelector('#no_media_selected').style.display = 'none'
    } else {
      document.querySelector('#no_media_selected').style.display = 'block'
    }
  }

  top_media() {
    let topmedia = [];
    document.querySelectorAll('[data-top-medium]').forEach((item, i) => {
      topmedia.push(item.getAttribute('data-top-medium'));
    });
    return [...new Set(topmedia)];
  }

  selected_top_media() {
    let selected_top = [];
    document.querySelectorAll('#table_2 .checkboxRG input[type="checkbox"]:checked').forEach((item, i) => {
      let top_medium = item.parentElement.parentElement.parentElement.getAttribute('data-top-medium');
      if (top_medium != null) {
        if (!window.active_media_search || (window.active_media_search && window.media_result.indexOf(parseInt(top_medium)) != -1)) {
          selected_top.push(top_medium);
        }
      }
    });
    return [...new Set(selected_top)];
  }

  selected_domains() {
    let selected = [];
    document.querySelectorAll('#table_2 .checkboxRG input[type="checkbox"]:checked').forEach((item, i) => {
      let domain = item.parentElement.parentElement.parentElement.getAttribute('data-domain-id');
      if (domain != null) {
        if (!window.active_media_search || (window.active_media_search && window.media_result.indexOf(parseInt(item.value)) != -1)) {
          selected.push(domain);
        }
      }
    });
    return new Set(selected);
  }

  create_tuples(top_medium) {
    let tuples = [];
    document.querySelectorAll(`[data-top-medium="${top_medium}"]`).forEach((item, i) => {
      let selected = item.querySelector('#table_2 .checkboxRG input[type="checkbox"]').checked
      let tuple = [];
      tuple.push(item.getAttribute('data-medium-id'));
      tuple.push(item.getAttribute('data-parent'));
      tuple.push(item.getAttribute('data-level'));
      tuple.push(selected);
      tuples.push(tuple);
    });
    tuples = tuples.sort((a, b) => { Number(a[2]) - Number(b[2]) }).reverse();
    return tuples
  }

  show_search_result() {
    let mode = this.data.get('mode');
    if (mode == 'list') {
      this.list_view();
    } else {
      this.edit_view();
    }
  }

  clear_search_result() {
    let mode = this.data.get('mode');
    if (mode == 'list') {
      this.list_view();
    } else {
      this.edit_view();
    }
  }
}
