import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source", "row", "group", "all", "toggle" ]

  update() {
    var url = window.location.href;
    var action = url.split('/').pop();
    var id = this.sourceTarget.value;
    var group = this.sourceTarget.getAttribute('data-group-id');
    url = url.replace(action, this.data.get('url'));
    url += `?user_id=${id}&status=${this.sourceTarget.checked}`;
    if (this.activeSearch()) {
      var groupsQuery = document.querySelector('#groups_query');
      var usersQuery  = document.querySelector('#group_users_query');

      url += `&groups_query=${groupsQuery.value}&users_query=${usersQuery.value}`;
    }
    if (this.sourceTarget.checked == true) {
      fetch(url)
      .then(
        this.rowTarget.setAttribute('data-selected', '')
      );
    } else {
      fetch(url)
      .then(
        this.rowTarget.removeAttribute('data-selected')
      );
    }
    this.checkSelection(group);
  }

  update_group_users() {
    var url = window.location.href;
    var action = url.split('/').pop();
    var id = this.groupTarget.value;
    url = url.replace(action, this.data.get('url'));
    url += `?group_id=${id}&status=${this.groupTarget.checked}`;
    if (this.activeSearch()) {
      var groupsQuery = document.querySelector('#groups_query');
      var usersQuery  = document.querySelector('#group_users_query');

      url += `&groups_query=${groupsQuery.value}&users_query=${usersQuery.value}`;
    }
    if (this.groupTarget.checked == true) {
      fetch(url)
      .then(
        this.insertGroupUsers(id)
      );
    } else {
      fetch(url)
      .then(
        this.removeGroupUsers(id)
      );
    }
    this.checkSelection();
  }

  insertGroupUsers(id) {
    if (this.activeSearch()) {
      var all_group_users = document.querySelectorAll(`#table_2 [data-group-id="${id}"][data-user-id][data-query-result]`);
    } else {
      var all_group_users = document.querySelectorAll(`#table_2 [data-group-id="${id}"][data-user-id]`);
    }

    all_group_users.forEach((item, i) => {
      item.querySelector('.checkboxRG input').checked = true;
      item.setAttribute('data-selected', '');
    });

    var header = document.querySelector(`#table_2 [data-group-header="${id}"]`)
    header.setAttribute('data-visible', '')

    if (this.activeSearch()) {
      header.setAttribute('data-selected-query-result', all_group_users.length);
    }

    this.checkSelection()
  }

  removeGroupUsers(id) {
    if (this.activeSearch()) {
      var all_group_users = document.querySelectorAll(`#table_2 [data-group-id="${id}"][data-user-id][data-query-result]`)
    } else {
      var all_group_users = document.querySelectorAll(`#table_2 [data-group-id="${id}"][data-user-id]`);
    }

    all_group_users.forEach((item, i) => {
      item.querySelector('.checkboxRG input').checked = false;
      item.removeAttribute('data-selected');
    });

    var header = document.querySelector(`#table_2 [data-group-header="${id}"]`)
    header.removeAttribute('data-visible')

    if (this.activeSearch()) {
      header.setAttribute('data-selected-query-result', 0);
    }

    this.checkSelection()
  }

  update_all() {
    var url = window.location.href;
    var action = url.split('/').pop();
    var id = this.allTarget.value;
    url = url.replace(action, this.data.get('url'));
    url += `?status=${this.allTarget.checked}`;
    if (this.activeSearch()) {
      var groupsQuery = document.querySelector('#groups_query');
      var usersQuery  = document.querySelector('#group_users_query');

      url += `&groups_query=${groupsQuery.value}&users_query=${usersQuery.value}`;
    }
    if (this.allTarget.checked == true) {
      fetch(url)
      .then(
        this.insertAll()
      );
    } else {
      fetch(url)
      .then(
        this.removeAll()
      );
    }
    this.checkSelection();
  }

  insertAll() {
    if (this.activeSearch()) {
      var headers = document.querySelectorAll(`#table_2 [data-group-header][data-query-result]`);
      var users   = document.querySelectorAll('#table_2 [data-user-id][data-query-result]');
    } else {
      var headers = document.querySelectorAll(`#table_2 [data-group-header]`);
      var users   = document.querySelectorAll('#table_2 [data-user-id]');
    }

    headers.forEach((item, i) => {
      item.querySelector('.checkboxRG input').checked = true;
      item.setAttribute('data-visible', '')

      if (this.activeSearch()) {
        var group = item.getAttribute('data-group-header');
        var all_group_users = document.querySelectorAll(`#table_2 [data-group-id="${group}"][data-user-id][data-query-result]`);
        item.setAttribute('data-selected-query-result', all_group_users.length);
      }
    });

    users.forEach((item, i) => {
      item.querySelector(`.checkboxRG input`).checked = true;
      item.setAttribute('data-selected', '');
    });

    this.checkSelection()
  }

  removeAll() {
    if (this.activeSearch()) {
      var headers = document.querySelectorAll(`#table_2 [data-group-header][data-query-result]`);
      var users   = document.querySelectorAll('#table_2 [data-user-id][data-query-result]');
    } else {
      var headers = document.querySelectorAll(`#table_2 [data-group-header]`);
      var users   = document.querySelectorAll('#table_2 [data-user-id]');
    }

    headers.forEach((item, i) => {
      item.querySelector('.checkboxRG input').checked = false;
      item.removeAttribute('data-visible')

      if (this.activeSearch()) {
        item.setAttribute('data-selected-query-result', 0);
      }
    });

    users.forEach((item, i) => {
      item.querySelector(`.checkboxRG input`).checked = false;
      item.removeAttribute('data-selected');
    });

    this.checkSelection()
  }

  checkSelection(group) {
    if (group) {
      this.checkGroupSelection(group);
    }
    var total = 0;
    var selected = 0;
    if (this.activeSearch()) {
      var users = document.querySelectorAll('#table_2 [data-query-result] [id^="campaign_group-users"]')
    } else {
      var users = document.querySelectorAll('#table_2 [id^="campaign_group-users"]')
    }
    users.forEach((item, i) => {
      total += 1;
      if (item.checked == true) {
        selected += 1
      }
    });
    if (total > 0 && selected == total) {
      document.querySelector('#all_groups_and_users').checked = true
    } else {
      document.querySelector('#all_groups_and_users').checked = false
    }
  }

  checkGroupSelection(group) {
    if (this.activeSearch()) {
      var selected_group_users = document.querySelectorAll(`#table_2 [data-group-id="${group}"][data-query-result][data-selected]`).length;
      var group_users = document.querySelectorAll(`#table_2 [data-group-id="${group}"][data-query-result]`).length;
    } else {
      var selected_group_users = document.querySelectorAll(`#table_2 [data-group-id="${group}"][data-selected]`).length;
      var group_users = document.querySelectorAll(`#table_2 [data-group-id="${group}"][data-user-id]`).length;
    }
    var selector = document.querySelector(`#table_2 .selector [id="group_${group}"]`);
    var header = document.querySelector(`#table_2 [data-group-header="${group}"]`);
    if (selected_group_users == group_users) {
      selector.checked = true;
    } else {
      selector.checked = false;
    }
    if (selected_group_users > 0) {
      header.setAttribute('data-visible', '');
    } else {
      header.removeAttribute('data-visible')
    }
  }

  activeSearch() {
    var groupsQuery = document.querySelector('#groups_query');
    var usersQuery  = document.querySelector('#group_users_query');

    return Boolean(groupsQuery.value.trim() || usersQuery.value.trim());
  }

  toggle() {
    var mode = this.toggleTarget.getAttribute('data-mode');

    if (mode == 'list') {
      this.toggleTarget.setAttribute('data-mode', 'edit');
      this.toggleTarget.innerText = 'Done';
      document.querySelector('#all_groups_and_users').parentElement.style.visibility = 'visible';
    } else {
      this.toggleTarget.setAttribute('data-mode', 'list');
      this.toggleTarget.innerText = 'Edit';
      document.querySelector('#all_groups_and_users').parentElement.style.visibility = 'hidden';
    }
    this.showUsers();
  }

  showUsers() {
    if (this.activeSearch()) {
      this.showSearchResult();
    } else {
      var mode = document.querySelector('#users-toggle').getAttribute('data-mode');
      var all_headers = document.querySelectorAll('#table_2 [data-group-header]');
      var all_users   = document.querySelectorAll('#table_2 [data-user-id]');

      all_headers.forEach((item, i) => {
        item.classList.add('hidden_row');
      });

      all_users.forEach((item, i) => {
        item.classList.add('hidden_row');
      });

      if (mode == 'list') {
        var headers = document.querySelectorAll('#table_2 [data-group-header][data-visible]');
        var users   = document.querySelectorAll('#table_2 [data-user-id][data-selected]');

        document.querySelectorAll(`#table_2 .checkboxRG input`).forEach((item, i) => {
          item.setAttribute('disabled', 'disabled');
        });
      } else {
        var headers = document.querySelectorAll('#table_2 [data-group-header]');
        var users   = document.querySelectorAll('#table_2 [data-user-id]');

        document.querySelectorAll(`#table_2 .checkboxRG input`).forEach((item, i) => {
          item.removeAttribute('disabled');
        });
      }

      headers.forEach((item, i) => {
        item.classList.remove('hidden_row');
      });

      users.forEach((item, i) => {
        item.classList.remove('hidden_row');
      });
    }
  }

  showSearchResult() {
    var mode        = document.querySelector('#users-toggle').getAttribute('data-mode');
    var all_headers = document.querySelectorAll('#table_2 [data-group-header]');
    var all_users   = document.querySelectorAll('#table_2 [data-user-id]');

    all_headers.forEach((item, i) => {
      item.classList.add('hidden_row');
    });

    all_users.forEach((item, i) => {
      item.classList.add('hidden_row');
    });

    if (mode == 'list') {
      var headers = [];
      document.querySelectorAll('#table_2 [data-group-header][data-selected-query-result]').forEach((item, i) => {
        if (parseInt(item.getAttribute('data-selected-query-result')) > 0) {
          headers.push(item);
        }
      });
      var users   = document.querySelectorAll('#table_2 [data-user-id][data-query-result][data-selected]');

      document.querySelectorAll(`#table_2 .checkboxRG input`).forEach((item, i) => {
        item.setAttribute('disabled', 'disabled');
      });
    } else {
      var headers = document.querySelectorAll('#table_2 [data-group-header][data-selected-query-result]');
      var users   = document.querySelectorAll('#table_2 [data-user-id][data-query-result]');

      document.querySelectorAll(`#table_2 .checkboxRG input`).forEach((item, i) => {
        item.removeAttribute('disabled');
      });
    }

    headers.forEach((item, i) => {
      item.classList.remove('hidden_row');
    });

    users.forEach((item, i) => {
      item.classList.remove('hidden_row');
    });
  }
}
