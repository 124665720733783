import { SocketUserController } from "./socket_user_controller";

export class LockExpiryController extends SocketUserController {
  connect() {
    window.that = this;
    this.create_socket_connection("LockChannel", {});
    this.lock_id = null;
    window.countdown = 60;
  }

  on_data(data) {
    this.lock_id = data["lock"];
    if (data["redirect"]) {
      history.back();
    } else {
      const dialog = document.getElementById("modalDialog");
      dialog.showModal();
      window.countdownTimeout = setTimeout(window.that.countdownRelease, 1000);
    }
  }

  countdownRelease() {
    window.countdown -= 1;
    let counter = document.querySelector("#countdown");
    counter.innerText = window.countdown + " seconden";
    if (window.countdown > 0) {
      window.countdownTimeout = setTimeout(window.that.countdownRelease, 1000);
    } else {
      window.clearTimeout(window.countdownTimeout);
    }
  }

  hold() {
    window.clearTimeout(window.countdownTimeout);
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    fetch("/admin/locks/hold", {
      method: "POST",
      body: JSON.stringify({
        id: this.lock_id,
      }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "X-CSRF-Token": token,
      },
    });
  }

  release() {
    window.clearTimeout(window.countdownTimeout);
    history.back();
  }

  on_connect() {}
}

export default LockExpiryController;
