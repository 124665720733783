import { Controller } from "@hotwired/stimulus";

const AnimationController = {};

AnimationController.create_style_string = function (property_map, duration) {
  const double_duration = parseFloat(duration) / 1000.0;
  const keys = Object.keys(property_map);

  let str = "";
  for (let i = 0; i < keys.length; i++) {
    str += `${keys[i]} ${double_duration}${i < keys.length - 1 ? "s, " : "s"}`;
  }

  return str;
};

AnimationController.animate = function (
  element,
  property_map,
  callback,
  duration,
) {
  const elem = element;

  const style_string = AnimationController.create_style_string(
    property_map,
    duration,
  );

  elem.ontransitionend = function () {
    callback();
    elem.style.transition = "";
  };

  elem.addEventListener(
    "MSTransitionEnd",
    function () {
      callback();
      elem.style.transition = "";
    },
    { once: true },
  );

  elem.style.transition = style_string;

  Object.entries(property_map).forEach(function ([k, v]) {
    elem.style[k] = v;
  });
};

export default class extends Controller {
  static targets = [
    "explanation",
    "explanation2",
    "timer",
    "slidingPanel",
    "dataPane",
  ];
  duration = 5;

  connect() {
    this.height = this.get_height();

    if (this.hasDataPaneTarget) {
      this.dataPaneTarget.style.height = `${this.height}px`;
    }
    this.explanationTarget.style.height = `${this.height + 41}px`;
    this.explanationTarget.querySelector("#explanation-text").style.height =
      `${this.height - 21}px`;

    this.draw_chart();

    const badge_element = document.querySelector("[data-badge-ids]");
    if (!badge_element) {
      this.initializeTimer();
    }
  }

  draw_chart() {
    if (window.answers && typeof window.answers["count"] == "object") {
      if (![3, 4].includes(window.qtype)) {
        if (document.querySelector(".kiosk") != undefined) {
          redgrasp.draw_answer_stats_chart("#chart2", "answers", 515, 500);
        } else if (parseInt($(".panel-body").css("width")) < 500) {
          redgrasp.draw_answer_stats_chart("#chart2", "answers", 265, 250);
        } else {
          redgrasp.draw_answer_stats_chart("#chart2", "answers", 315, 300);
        }
      } else {
        if (document.querySelector(".kiosk") != undefined) {
          redgrasp.draw_poll_stats_chart("#chart2", "answers", 515, 500);
        } else if (parseInt($(".panel-body").css("width")) < 500) {
          redgrasp.draw_poll_stats_chart("#chart2", "answers", 265, 250);
        } else {
          redgrasp.draw_poll_stats_chart("#chart2", "answers", 315, 300);
        }
      }
    }
  }

  initializeTimer() {
    if (!this.hasTimerTarget) {
      return;
    }
    const that = this;
    const dur_in_quarter_seconds = this.duration * 4;
    const radius = 6;
    const offset = 8;
    let remaining = dur_in_quarter_seconds;
    clearInterval(window.explanation_timer);
    this.timerTarget.style.display = "inline-block";
    window.explanation_timer = setInterval(function () {
      let t = remaining;
      remaining -= 1;
      const flag = remaining < 0.5 * dur_in_quarter_seconds ? 1 : 0;
      let fraction =
        (dur_in_quarter_seconds - remaining) / dur_in_quarter_seconds;
      if (fraction === 1) {
        fraction = 0.9999;
      }
      const coords = that.calculate_coordinates(fraction, radius, offset);
      const path = `M${offset} 2 A ${radius} ${radius}, 0, ${flag}, 1, ${coords[0]} ${coords[1]} L ${offset} ${offset} Z`;
      that.timerTarget.querySelector("path").setAttribute("d", path);
      if (t <= 0) {
        const timer_element = document.querySelector(".expl-timer");
        const regular =
          timer_element.getAttribute("class").indexOf("expl-timer") !== -1;
        timer_element.parentNode.removeChild(timer_element);
        if (regular) {
          that.slide(that.height);
        } else {
          redgrasp.lq_slide(that.height);
        }
        clearInterval(window.explanation_timer);
      }
    }, 250);
  }

  toggle() {
    clearInterval(window.explanation_timer);
    if (this.hasTimerTarget) {
      const timer_element = document.querySelector(".expl-timer");
      timer_element.parentNode.removeChild(timer_element);
    }
    this.slide(this.height);
  }

  slide(height, show_stats) {
    const that = this;
    if (!this.hasDataPaneTarget) {
      const current_height = document.querySelector(".q-question").clientHeight;
      const announcement = document.querySelector("#explanation_announcement");
      announcement.toggle("shown_announcement");
      const icon = announcement.querySelector("i");
      if (icon.getAttribute("class").indexOf("fa-chevron-circle-down") !== -1) {
        icon.setAttribute("class", "fas fa-chevron-circle-up fa-lg"); //.replace('fa-chevron-circle-down', 'fa-chevron-circle-up')
      } else {
        icon.setAttribute("class", "fas fa-chevron-circle-down fa-lg"); //.replace('fa-chevron-circle-up', 'fa-chevron-circle-down')
      }
      document.querySelector("#announcement_explained").toggle("hidden");
      if (
        announcement.getAttribute("class").indexOf("shown_announcement") !== -1
      ) {
        announcement.querySelector(".trigger-label").innerText =
          I18n.t("explanation2");
      } else {
        announcement.querySelector(".trigger-label").innerText =
          I18n.t("explanation1");
      }
    } else {
      const current_height = this.dataPaneTarget.clientHeight;

      if (current_height > 0) {
        this.open_explanation(show_stats);
      } else {
        this.close_explanation(show_stats);
      }
      const icon = that.explanationTarget.querySelector("i");
      if (icon.getAttribute("class").indexOf("fa-chevron-circle-down") !== -1) {
        icon.setAttribute("class", "fas fa-chevron-circle-up fa-lg"); //.replace('fa-chevron-circle-down', 'fa-chevron-circle-up')
      } else {
        icon.setAttribute("class", "fas fa-chevron-circle-down fa-lg"); //.replace('fa-chevron-circle-up', 'fa-chevron-circle-down')
      }
    }
  }

  get_height() {
    if (document.querySelector(".kiosk") != undefined) {
      window.original_height = parseInt(
        document.querySelector(".kiosk-body").clientHeight - 41,
      );
    } else {
      window.original_height =
        parseInt(
          document.querySelector(
            "#q-answer .panel-body,#lq-answer .panel-body, #question-scroll-view",
          ).clientHeight,
        ) - 31;
    }
    return window.original_height;
  }

  calculate_coordinates(percentage, radius, offset) {
    const exprBase = -percentage * 2 * Math.PI + 0.5 * Math.PI;
    const x = offset + Math.cos(exprBase) * radius;
    const y = offset + -Math.sin(exprBase) * radius;
    return [x, y];
  }

  open_explanation() {
    const that = this;
    this.explanation2Target.style.display = "none";
    this.explanationTarget.querySelector(".trigger-label").innerText =
      I18n.t("explanation2");
    this.explanation2Target.querySelector(".trigger-label").innerText =
      I18n.t("explanation2");
    this.slidingPanelTarget.style.zIndex = 20;
    this.dataPaneTarget.classList.add("close");
    AnimationController.animate(
      this.dataPaneTarget,
      { height: "0px" },
      function () {
        that.dataPaneTarget.style.visibility = "hidden";
        that.dataPaneTarget
          .querySelectorAll('[class^="col-"]')
          .forEach((item, _) => {
            item.style.display = "none";
          });
        const title = that.dataPaneTarget.querySelector("#title");
        if (title) {
          title.style.display = "none";
        }
        that.deactivate_data_pane_toggle();
      },
      400,
    );
  }

  close_explanation(show_stats) {
    const that = this;
    if (show_stats) {
      that.slidingPanelTarget.style.zIndex = 20;
      that.dataPaneTarget.style.visibility = "visible";
      that.dataPaneTarget
        .querySelectorAll('[class^="col-"]')
        .forEach((item, i) => {
          item.style.display = "block";
        });
      const title = that.dataPaneTarget.querySelector("#title");
      if (title) {
        title.style.display = "block";
      }
    }
    if (this.dataPaneTarget.getAttribute("class").indexOf("close") !== -1) {
      AnimationController.animate(
        this.dataPaneTarget,
        { height: `${that.height}px` },
        function () {
          that.hide_sliding_panel(show_stats);
        },
        500,
      );
      this.dataPaneTarget.classList.remove("close");
    } else {
      this.hide_sliding_panel(show_stats);
    }
    this.explanation2Target.querySelector(".trigger-label").innerText =
      I18n.t("explanation1");
    this.explanationTarget.querySelector(".trigger-label").innerText =
      I18n.t("explanation1");
  }

  hide_sliding_panel(show_stats) {
    if (!show_stats) {
      this.dataPaneTarget.style.visibility = "hidden";
      this.dataPaneTarget
        .querySelectorAll('[class^="col-"]')
        .forEach((item, i) => {
          item.style.display = "none";
        });
      const title = this.dataPaneTarget.querySelector("#title");
      if (title) {
        title.style.display = "none";
      }
      this.deactivate_data_pane_toggle();
      this.slidingPanelTarget.style.zIndex = -1;
    }
    this.explanation2Target.style.display = "block";
    this.explanationTarget
      .querySelector("i")
      .setAttribute("class", "fas fa-chevron-circle-up fa-lg"); //.classList.replace('fa-chevron-circle-down', 'fa-chevron-circle-up')
  }

  deactivate_data_pane_toggle() {
    const toggle = document.querySelector("[data-pane-toggle]");
    if (toggle) {
      toggle.setAttribute("data-pane-toggle", "inactive");
      toggle.querySelector("#bar_chart").style.fill = "#ccc";
    }
  }

  toggle_data_pane(event) {
    // Because in IE 11 CustomEvent doesn't support detail, we can't use
    // the following code:
    // const state = event.detail.state;
    const state = document
      .querySelector("[data-pane-toggle]")
      .getAttribute("data-pane-toggle");
    this.close_explanation(state === "active");
  }

  disconnect() {
    clearInterval(window.explanation_timer);
  }
}
