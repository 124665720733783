import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['message']

  connect() {
    console.log(`Is that a flash message I see there?!?`);
    let scrollHeight = document.querySelector('html').scrollTop;
    let overlay = document.createElement('div');
    overlay.classList.add('case-overlay');
    document.querySelector('html').prepend(overlay);
    overlay.style.top = scrollHeight + 'px';
    overlay.style.background = 'rgba(51, 51, 51, 0.6)'
    overlay.style.height = '100%'
    overlay.style.zIndex = 100000
    document.querySelector('html').style.position = 'relative';
    document.querySelector('body').style.position = 'relative';
    document.querySelector('html').style.overflow = 'hidden';
    document.querySelector('body').style.overflow = 'hidden';
    document.querySelector('html').style.minHeight = '100%';
    document.querySelector('body').style.minHeight = '100%';
    let container = document.createElement('div');
    container.classList.add('container-original');
    let innerDiv = document.createElement('div');
    innerDiv.classList.add('col-sm-12', 'col-md-4', 'col-md-offset-4');
    innerDiv.style.opacity = 1;
    innerDiv.style.padding = '0px';
    let closeIcon = document.createElement('i');
    closeIcon.classList.add('fas', 'fa-times', 'fa-2x');
    closeIcon.setAttribute('id', 'close_case_page');
    closeIcon.setAttribute('data-controller', 'pin-code');
    closeIcon.setAttribute('data-action', 'click->pin-code#close');
    let caseModal = document.createElement('div');
    caseModal.classList.add('case-modal');
    caseModal.style.textAlign = 'center';
    caseModal.style.width = '100%';
    caseModal.style.minHeight = '100px';
    innerDiv.append(closeIcon);
    innerDiv.append(caseModal);
    container.append(innerDiv);
    overlay.append(container);
    let url = '/modals/resource_locked'
    fetch(url)
    .then(response => response.text())
    .then(data => {
      document.querySelector('.case-modal').innerHTML = data;
      let message = document.querySelector('.case-modal #message');
      console.log(this.messageTarget.innerText);
      message.innerText = this.messageTarget.innerText;
    });
    let modal = document.querySelector('.case-modal');
    let margin = parseInt((window.innerHeight - parseInt(modal.offsetHeight)) / 2);
    modal.style.top = margin + 'px';
    closeIcon.style.top = margin + 'px';
  }
}
