import { SocketUserController } from './socket_user_controller'

export class CsmDashboardController extends SocketUserController {
  connect() {
    this.create_socket_connection('CsmDashboardChannel', {});
    let spinners = document.querySelectorAll('.csm-websocket-spinner');

    spinners.forEach((item, i) => {
      let source = item.getAttribute('data-source');
      let url = window.location.href;
      let action = url.split('/').pop();
      if (action == 'organization') {
        url = url.replace(action, source);
      } else if (action == 'engagement') {
        url = url.replace(action, source);
        url += `?campaign_id=${item.getAttribute('data-campaign-id')}`
      } else {
        url += `/${source}`;
      }

      fetch(url);
    });
  }

  on_data(data) {
    let target = data['target'];
    let html = data['html'];
    let campaign = data['campaign'];

    if (campaign != undefined) {
      document.querySelector('[data-campaign-id="' + campaign + '"]').parentNode.innerHTML = html;
    } else {
      document.querySelector('[data-source="' + target + '"]').parentNode.innerHTML = html;
    }

  }

  on_connect() {
    console.log('Connected!!');
  }
}

export default CsmDashboardController;
