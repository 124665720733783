import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    document.onkeyup = function (event) {
      const dialog = document.getElementById('modalDialog');
      if (!dialog.hasAttribute('open')) {
        const focussed_review = document.querySelector('.focus');
        if (focussed_review) {
          const id = focussed_review.getAttribute('id');
          if (event.which == 75 || event.keyCode == 75) {
            window.dispatchEvent(new CustomEvent("moveUp", {detail: {focus: id}, bubbles: false}))
          }
          if (event.which == 74 || event.keyCode == 74) {
            window.dispatchEvent(new CustomEvent("moveDown", {detail: {focus: id}, bubbles: false}));
          }
          if (event.which == 13 || event.keyCode == 13) {
            window.dispatchEvent(new CustomEvent("editReview", {detail: {focus: id}, bubbles: false}));
          }
        } else {
          let reviews = document.querySelectorAll('.review')
          if (reviews.length > 0) {
            const id = reviews[0].getAttribute('id');
            if (event.which == 75 || event.keyCode == 75) {
              // window.dispatchEvent(new CustomEvent("moveUp", {detail: {focus: id}, bubbles: false}))
              reviews[0].classList.add('focus');
            }
            if (event.which == 74 || event.keyCode == 74) {
              // window.dispatchEvent(new CustomEvent("moveDown", {detail: {focus: id}, bubbles: false}));
              reviews[0].classList.add('focus');
            }
            if (event.which == 13 || event.keyCode == 13) {
              window.dispatchEvent(new CustomEvent("editReview", {detail: {focus: id}, bubbles: false}));
            }
          }
        }
      }
    }
  }

  edit(event) {
    if (event.type == 'click' || (event.type == 'editReview' && this.element.getAttribute('id') === event.detail.focus)) {
      if (event.target.tagName !== 'A' && event.target.tagName !== 'I') {
        this.focus()
        const url = this.data.get('url');
        fetch(url, {
          headers: {
            Accept: 'application/json'
          }
        })
        .then(response => response.json())
        .then(data => {
          const dialog = document.getElementById('modalDialog');
          dialog.innerHTML = data.html;
          $(document).on('ajax:complete', '#modalForm', function(e, data, status, xhr) {
            let focussed_review = document.querySelector('.review.focus');
            let focussed_id = focussed_review.getAttribute('id');
            focussed_review.outerHTML = data.responseJSON.html;
            document.querySelector(`#${focussed_id}`).classList.add('focus');
            const dialog = document.getElementById('modalDialog');
            dialog.close();
          });
          dialog.addEventListener('cancel', (event) => {
            event.preventDefault();
            window.dispatchEvent(new CustomEvent("closeDialog", {detail: {focus: 99}, bubbles: false}));
          });
          dialog.showModal();
        });
      }
    }
  }

  closeDialog() {
    const dialog = document.getElementById('modalDialog');
    dialog.close();
  }

  focus() {
    document.querySelectorAll('.review').forEach((item, i) => {
      item.classList.remove('focus');
    });
    this.element.classList.add('focus');
  }

  unfocus() {
    this.element.classList.remove('focus')
  }

  moveUp() {
    if (this.element.getAttribute('id') === event.detail.focus) {
      let previous = this.element.previousElementSibling;
      if (previous) {
        this.unfocus();
        previous.classList.add('focus');
      }
    }
  }

  moveDown(event) {
    if (this.element.getAttribute('id') === event.detail.focus) {
      let next = this.element.nextElementSibling;
      if (next) {
        this.unfocus();
        next.classList.add('focus');
      }
    }
  }
}
