import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle"]
  static values = { displayStatus: String }

  toggle(event) {
    let hasMode = document.querySelector('[data-media-select-mode]');
    let mode;
    if (hasMode) { mode = hasMode.getAttribute('data-media-select-mode') }
    console.log(`Mode: ${mode}`);
    const shiftPressed = event.altKey || event.metaKey
    document.querySelectorAll(`[data-nested-collapse-parent="${this.data.get('id')}"]`).forEach((item, i) => {
      if (mode == 'list') {
        if (item.hasAttribute('data-shown')) {
          if (item.getAttribute('data-nested-collapse-display-status-value') == 'hidden') {
            this.expand(item, mode, shiftPressed);
          } else {
            this.collapse(item, mode, shiftPressed);
          }
        }
      } else {
        if (item.getAttribute('data-nested-collapse-display-status-value') == 'hidden') {
          this.expand(item, mode, shiftPressed);
        } else {
          this.collapse(item, mode, shiftPressed);
        }
      }
    });
  }

  should_display(child) {
    return child.getAttribute('data-nested-collapse-display-status-value') == 'hidden';
  }

  expand(item, mode, affect_all) {
    item.style.display = 'table-row';
    item.setAttribute('data-nested-collapse-display-status-value', 'shown');
    this.toggleTarget.classList.replace('fa-caret-right', 'fa-caret-down');
    for (let nextChild = item.nextElementSibling; nextChild.getAttribute('data-nested-collapse-level') > item.getAttribute('data-nested-collapse-level'); nextChild = nextChild.nextElementSibling) {
      if (affect_all) {
        if (mode) {
          if (mode == 'list') {
            if (nextChild.hasAttribute('data-shown')) {
              nextChild.setAttribute('data-nested-collapse-display-status-value', 'shown')
            }
          } else {
            nextChild.setAttribute('data-nested-collapse-display-status-value', 'shown')
          }
        } else {
          nextChild.setAttribute('data-nested-collapse-display-status-value', 'shown')
        }
      }
      if (this.should_display(nextChild)) {
        nextChild.style.display = 'none';
      } else {
        nextChild.style.display = 'table-row';
        const previousChild = nextChild.previousElementSibling;
        if (previousChild.getAttribute('data-nested-collapse-level') < nextChild.getAttribute('data-nested-collapse-level')) {
          const toggleIcon = previousChild.querySelector('.fa-caret-right');
          if (toggleIcon != null) {
            toggleIcon.classList.replace('fa-caret-right', 'fa-caret-down');
          }
        }
      }

    }
  }

  collapse(item, mode, affect_all) {
    item.style.display = 'none';
    item.setAttribute('data-nested-collapse-display-status-value', 'hidden');
    this.toggleTarget.classList.replace('fa-caret-down', 'fa-caret-right');
    for (let nextChild = item.nextElementSibling; nextChild.getAttribute('data-nested-collapse-level') > item.getAttribute('data-nested-collapse-level'); nextChild = nextChild.nextElementSibling) {
      if (affect_all) {
        if (mode) {
          if (mode == 'list') {
            if (nextChild.hasAttribute('data-shown')) {
              nextChild.setAttribute('data-nested-collapse-display-status-value', 'hidden')
            }
          } else {
            nextChild.setAttribute('data-nested-collapse-display-status-value', 'hidden')
          }
        } else {
          nextChild.setAttribute('data-nested-collapse-display-status-value', 'hidden')
        }
      }
      nextChild.style.display = 'none';
      const previousChild = nextChild.previousElementSibling;
      if (previousChild.getAttribute('data-nested-collapse-level') < nextChild.getAttribute('data-nested-collapse-level')) {
        const toggleIcon = previousChild.querySelector('.fa-caret-down');
        if (toggleIcon != null) {
          toggleIcon.classList.replace('fa-caret-down', 'fa-caret-right');
        }
      }
    }
  }
}
