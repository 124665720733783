import { Controller } from "@hotwired/stimulus"

export default class QuestionController extends Controller {
    static targets = ['medium', 'questions'];

    selected_question_id = -1;
    should_add = true;

    private_content_id = -1;

    fetch_json(url) {
        return fetch(url)
            .then(response => response.text())
            .then(data => {
                try {
                    return JSON.parse(data.toString())
                } catch (e) {
                    console.log(data.toString())
                    return '<div>An error occurred during question receive</div>'
                }
            })
    }

    fetch_html(url) {
        return this.fetch_json(url)
            .then(json => json['html'])
    }

    retrieve_question_details(event, controller) {
        const target = event.target
        const question = target.children[target.selectedIndex]
        const container = document.getElementById('question_details');

        const question_id = question.value
        controller.selected_question_id = parseInt(question_id)

        let url = window.location.href
        let action = url.split('/').pop()

        url = url.replace(action, 'question_details')
        url += `?question=${question_id}`

        if (this.mediumTarget.value.indexOf(':') !== -1) {
            url += `&uids=${this.mediumTarget.value}`
        }

        controller.fetch_html(url)
            .then(html => container.innerHTML = html)
    }

    update_options() {
        let medium_id = this.mediumTarget.value

        let action = window.location.href.split('/').pop()
        let url = window.location.href.replace(action, 'get_questions')

        url += `?medium=${medium_id}`

        this.fetch_html(url).then(html => {
            html = html.replaceAll('&lt;', '<')
            html = html.replaceAll('&gt;', '>')

            this.questionsTarget.innerHTML = html
            this.retrieve_question_details({target: document.getElementById('live_quiz_question_question_id')}, this)
        })
    }

    replace_borrowed_and_private_content_option() {
        const domain_name = document.getElementById('live_quiz_question_private_content_id').value;
        const borrowed_name = document.getElementById('live_quiz_question_borrowed_media_id').value;
        const private_id = domain_name.split(' - ').pop()
        const borrowed_id = borrowed_name.split(' - ').pop()
        const private_name_length = domain_name.length - (private_id.length + 3)
        const borrowed_name_length = borrowed_name.length - (borrowed_id.length + 3)

        const option_tags = Array.prototype.slice.call(document.getElementsByTagName('OPTGROUP'), 0 )
        option_tags.filter((t) => t.label === domain_name || t.label === borrowed_name).forEach((o) => {
            if (o.label === domain_name) {
                o.label = o.label.substr(0, private_name_length)
            } else if (o.label === borrowed_name) {
                o.label = o.label.substr(0, borrowed_name_length)
            }

            Array.prototype.slice.call(o.children, 0 ).forEach((o) => o.classList.add('private-medium'))
        })

        this.private_content_id = parseInt(id)
    }

    connect() {
        //this.replace_question_select();
        const that = this
        document.getElementById('live_quiz_question_question_id')
            .addEventListener('change', function (event) {
                that.retrieve_question_details(event, that)
            })

        this.replace_borrowed_and_private_content_option()
    }
}
