import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source", "replace"]

  change() {
    var url = this.data.get('url');
    url += `?source=${this.sourceTarget.value}`;
    fetch(url)
    .then(response => response.json())
    .then(data => {
      this.replaceTarget.innerHTML = data.html;
    });
  }
}
