import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "mode", "toggle", "link" ]

  toggle(event) {
    event.preventDefault();
    var url = window.location.href;
    var action = url.split('/').pop();
    url = url.replace(action, this.data.get('url'));
    fetch(url)
    .then(response => response.json())
    .then(data => {
      if (this.linkTarget.parentElement.classList.contains('active')) {
        this.linkTarget.parentElement.classList.remove('active');
      } else {
        this.linkTarget.parentElement.classList.add('active');
      }
      document.querySelector('#table_1').innerHTML = data.html;

      document.querySelectorAll('#table_1 [data-group-id]').forEach((item, i) => {
        item.querySelector('.checkboxRG input').removeAttribute('disabled');
      });

      this.checkGroupSelection();
    });
  }

  mode() {
    var mode = this.modeTarget.getAttribute('data-mode');

    if (mode == 'list') {
      document.querySelector('#general-groups-toggle').style.visibility = 'hidden';
    } else {
      document.querySelector('#general-groups-toggle').style.visibility = 'visible';
    }
  }

  checkGroupSelection() {
    if (this.activeSearch()) {
      var groups = document.querySelectorAll('#table_1 [data-query-result] [id^="campaign_groups_"]');
    } else {
      var groups = document.querySelectorAll('#table_1 [id^="campaign_groups_"]');
    }

    var total = 0;
    var selected = 0;

    groups.forEach((item, i) => {
      total += 1;
      if (item.checked == true) {
        selected += 1
      }
    });

    if (total > 0 && selected == total) {
      document.querySelector('#all_groups').checked = true
    } else {
      document.querySelector('#all_groups').checked = false
    }
  }

  activeSearch() {
    var groupsQuery = document.querySelector('#groups_query');
    var usersQuery  = document.querySelector('#group_users_query');

    return Boolean(groupsQuery.value.trim() || usersQuery.value.trim());
  }
}
