import { Controller } from "@hotwired/stimulus";

export default class LiveQuizzesController extends Controller {
  push_question_move(question) {
    const questions = document.getElementsByClassName("question");

    let index = -1;
    for (let i = 0; i < questions.length; i++) {
      if (questions[i] !== question) continue;
      index = i;
      break;
    }

    if (index === -1) return;

    const question_id = question.id.split("-").pop();

    let url = window.location.href;
    let action = url.split("/").pop();

    url = url.replace(action, "move_question");
    url += `?question_id=${question_id}&question_index=${index}`;

    fetch(url);
  }

  drag_element(element, other_elements) {
    const that = this;

    let from_x = 0,
      from_y = 0;
    let clone = undefined;

    const start_left = element.style.left;
    const start_top = element.style.top;
    const start_width = element.style.width;

    const handle = element.querySelector(".drag-handle");
    handle.onmousedown = start_drag;

    function start_drag(e) {
      e.preventDefault();

      const bar_color = "white";

      clone = element.cloneNode(true);
      clone.childNodes.forEach(function (node) {
        if (node.style !== undefined) {
          node.style.color = bar_color;
        }
      });
      clone.style.background = bar_color;
      clone.style.borderStyle = "solid";
      clone.style.borderColor = "#4183c4";
      element.insertAdjacentElement("afterend", clone);

      element.style.width = element.getBoundingClientRect().width + "px";
      element.style.position = "absolute";

      from_x = e.clientX;
      from_y = e.clientY;

      document.onmouseup = stop_drag;
      document.onmousemove = drag;
    }

    function detect_overlap() {
      const dragging_box = element.getBoundingClientRect();

      let closest = [-1, undefined];

      for (let i = 0; i < other_elements.length; i++) {
        if (other_elements[i] === element) {
          continue;
        }

        const other_box = other_elements[i].getBoundingClientRect();

        const distance = other_box.y - dragging_box.y;

        if (
          Math.abs(distance) < Math.abs(closest[0]) ||
          closest[1] === undefined
        ) {
          closest[0] = distance;
          closest[1] = other_elements[i];
        }
      }

      if (closest[1] === undefined || closest[1] === clone) {
        return;
      }

      const position = closest[0] < 0 ? "beforebegin" : "afterend";
      const parent = clone.parentNode;

      parent.removeChild(clone);
      closest[1].insertAdjacentElement(position, clone);
    }

    function drag(e) {
      e.preventDefault();

      const to_x = from_x - e.clientX;
      const to_y = from_y - e.clientY;

      element.style.left = element.offsetLeft - to_x + "px";
      element.style.top = element.offsetTop - to_y + "px";

      from_x = e.clientX;
      from_y = e.clientY;

      detect_overlap();
    }

    function stop_drag() {
      document.onmouseup = null;
      document.onmousemove = null;

      element.style.position = "";
      from_x = 0;
      from_y = 0;

      element.style.left = start_left;
      element.style.top = start_top;
      element.style.width = start_width;

      const parent = element.parentNode;
      parent.removeChild(element);
      clone.insertAdjacentElement("afterend", element);
      clone.parentNode.removeChild(clone);

      that.push_question_move(element);
    }
  }

  connect() {
    let questions = document.querySelectorAll(".question");

    const that = this;

    questions.forEach((question) => {
      that.drag_element(question, questions);
    });
  }
}
