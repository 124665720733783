import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "previousMonth", "nextMonth" ]

  previous() {
    if (!this.previousMonthTarget.hasAttribute('data-disabled')) {
      var url = window.location.href;
      var action = url.split('&').pop();
      url = url.replace('shift', this.data.get('url'));
      if (action.match(/month=/)) {
        url = url.replace(action, `month=${this.data.get('previous')}`);
      } else {
        url += `&month=${this.data.get('previous')}`
      }
      fetch(url)
      .then(response => response.json())
      .then(data => {
        document.querySelector('.date-picker-calendar').outerHTML = data.html;
      });
    }
  }

  next() {
    if (!this.nextMonthTarget.hasAttribute('data-disabled')) {
      var url = window.location.href;
      var action = url.split('&').pop();
      url = url.replace('shift', this.data.get('url'));
      if (action.match(/month=/)) {
        url = url.replace(action, `month=${this.data.get('next')}`);
      } else {
        url += `&month=${this.data.get('next')}`
      }
      fetch(url)
      .then(response => response.json())
      .then(data => {
        document.querySelector('.date-picker-calendar').outerHTML = data.html;
      });
    }
  }

  select() {
    var target_date = document.querySelector('[type="radio"]:checked').value
    var date_field = document.querySelector('[data-target-date]');
    if (date_field != undefined) {
      date_field.value = target_date;
    }
  }
}
