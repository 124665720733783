import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "bar" ]
    comment_visible = false;
    click_lock = false;

    id() {
        return this.element.id.split('_').pop();
    }

    flagged() {
        return Boolean(window.location.href.match(/flagged/));
    }

    notifications() {
        return Array.prototype.slice.call(document.getElementsByClassName('notification2'));
    }

    update_badges(counts) {
        window.dispatchEvent(new CustomEvent('updateBadges', {detail: counts}))
    }

    update_status_bar(html) {
        // TODO make action target
        const status_bar_element = this.element.querySelector('[data-notification-target]');
        status_bar_element.innerHTML = html;
    }

    update_inbox_icon(counts) {
        window.dispatchEvent(new CustomEvent('updateIcon', {detail: counts}))
    }

    trash() {
        this.toggle_trashed(true)
    }

    return_trashed() {
        this.toggle_trashed(false)
    }

    toggle_trashed(trashed) {
        const url = `/notifications/${trashed ? 'trash' : 'return'}?id=` + this.id();

        const that = this;
        this.click_lock = true;

        fetch(url).then(response => response.json()).then(res => {
            if (res.remove_from_list) {
                that.element.style.display = 'none';
            }
            that.update_badges(res.counts)
            that.update_inbox_icon(res.counts)
            that.click_lock = false;
        });
    }

    mark_read(event) {
        if (event.target.id === 'status-indicators' || this.click_lock) {
            return;
        }

        // const status = this.element.querySelector('.full-comment');

        Array.prototype.slice.call(document.getElementsByClassName('snippet')).forEach(function (snippet) {
            snippet.style.display = 'block';
        })
        Array.prototype.slice.call(document.getElementsByClassName('full-comment')).forEach(function (snippet) {
            snippet.style.display = 'none';
        })
        if (this.comment_visible === false) {
            const notifications = document.getElementsByClassName('notification2')
            const last_notification_in_list = notifications[notifications.length - 1];

            const snippet = document.querySelector('.snippet')
            const collapsed = this.element === last_notification_in_list ? parseInt(snippet.style.height.replace('px', '')) : 0;

            this.element.querySelector('.snippet').style.display = 'none';
            this.element.querySelector('.full-comment').style.display = 'block';
            this.comment_visible = true;

            if (this.element === last_notification_in_list) {
                const full = parseInt(this.element.querySelector('.full-comment').style.height.replace('px', ''))

                const element = document.getElementsByClassName('note-list')[0];
                element.scrollTop = (element.scrollTop + (full + 25));
            }
        } else {
            this.element.querySelector('.snippet').style.display = 'block';
            this.element.querySelector('.full-comment').style.display = 'none';
            this.comment_visible = false;
        }

        const url = '/notifications/mark_as_read?id=' + this.id();
        const that = this;

        fetch(url).then(response => response.json()).then(res => {
            that.update_status_bar(res.html)
            that.element.classList.remove('unread')
            that.element.classList.add('read')
            that.update_badges(res.counts);
            that.update_inbox_icon(res.counts);

            that.click_lock = false;
        });
    }

    mark_unread() {
        const url = '/notifications/mark_as_unread?id=' + this.id();
        const that = this;
        this.click_lock = true;

        fetch(url).then(response => response.json()).then(res => {
            that.update_status_bar(res.html)
            that.element.classList.remove('read')
            that.element.classList.add('unread')
            that.update_badges(res.counts);
            that.update_inbox_icon(res.counts)

            that.click_lock = false;
        });
    }

    flag() {
        const url = `/notifications/flag?id=${this.id()}&flagged=${this.flagged()}`
        const that = this;
        this.click_lock = true;

        fetch(url).then(response => response.json()).then(res => {
            that.update_status_bar(res.html)
            that.update_badges(res.counts)
            if (res.remove_from_list) {
                that.element.style.display = 'none';
            }
            that.click_lock = false;
        })
    }
}
