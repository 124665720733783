import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  toggle() {
    var bar = document.querySelector('.env-org-indicator-bar');
    if (bar.classList.contains('shown')) {
      bar.classList.remove('shown');
    } else {
      bar.classList.add('shown');
    }
  }
}
