import { UserActionController } from './user_action_controller'

export default class extends UserActionController {
    update_timer(time_left) {
        const timer_div = document.getElementById('timer')

        if (timer_div) {
            timer_div.innerHTML = time_left.toString()
        }
    }

    select_option(caller) {
        const buttons = Array.from(document.querySelectorAll('.option-button'))
        buttons.reduce((left, right) => left.classList.contains('selected') ? left : right).classList.remove('selected')
        caller.classList.add('selected')
    }

    add_option_buttons() {
        const buttons = Array.from(document.querySelectorAll('.option-button'))

        const that = this
        buttons.forEach(button => button.onclick = () => {
            that.select_option(button)
        })
    }

    send_option() {
        const buttons = document.getElementsByClassName('selected')
        if (buttons.length === 0) {
            console.log('No option selected')
            return
        }
        const children = Array.from(buttons[0].childNodes);
        const button = children.reduce((left, right) => left.tagName === 'INPUT' ? left : right)

        let url = window.location.href.split('?')[0]
        url += "/answer?option=" + button.id
        url += "&lq_session=" + window['quiz_state']['session']

        const timer_value = document.getElementById('timer').innerHTML

        const source = document.getElementsByTagName('small')[0].innerHTML
        this.fetch_partial(url)
            .then(data => this.swap_content(data))
            .then(_ => document.getElementsByTagName('small')[0].innerHTML = source)
            .then(_ => document.getElementById('timer'))
            .then(div => div.innerHTML = timer_value)
    }

    add_send_action() {
        const buttons = Array.from(document.getElementsByTagName('input'));

        if (buttons.length <= 0) return;

        const button = buttons.reduce((left, right) => left.type === 'submit' ? left : right);

        const that = this
        button.onclick = () => {
            that.send_option(button);
        }
    }

    update_components() {
        super.update_components();
        this.add_option_buttons();
        this.add_send_action();
    }

    on_data(data) {
        switch (data['action']) {
            case 'timer':
                this.update_timer(data['time_left'])
                break
            case 'redirect':
                document.cookie = `_lq_session=${window['quiz_state']['session']}; path=/`
                window.location.href = data['path'] + '?lq_session=' + window['quiz_state']['session']
                break
            default:
                super.on_data(data);
        }
    }

    on_connect() {
        super.on_connect();
    }
}
