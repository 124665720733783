import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle"]

  toggle() {
    let that = this;
    let url = this.data.get('url');
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    fetch(url, {
      method: "PUT",
      body: JSON.stringify({
        domain_id: this.toggleTarget.value,
        status: this.toggleTarget.checked
      }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "X-CSRF-Token": token
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.action == 'add') {
        let inserted = false;
        let selection = document.querySelectorAll('#table_2 tbody tr[data-domain-header]');
        if (selection.length > 0) {
          selection.forEach((item, i) => {
            if (!inserted && item.getAttribute('data-index') > data.index) {
              let parent = item.parentNode
              item.insertAdjacentHTML('beforebegin', data.html);
              inserted = true
            }
          });
          if (!inserted) {
            document.querySelector('#table_2 tbody').insertAdjacentHTML('beforeend', data.html);
          }
        } else {
          let placeholder = document.querySelector('#table_2 .empty-flip-table');
          if (placeholder != undefined) {
            placeholder.style.display = 'none';
          }
          document.querySelector('#table_2 tbody').innerHTML = data.html
        }
        window.dispatchEvent(new CustomEvent("initialLoad", {detail: {mode: 'addDomain'}}))
      } else {
        document.querySelector(`#table_2 [data-domain-header="${that.toggleTarget.value}"]`).remove()
        document.querySelectorAll(`#table_2 [data-domain-id="${that.toggleTarget.value}"]`).forEach((item, i) => {
          item.remove()
        });
      }
      window.dispatchEvent(new CustomEvent("checkCollection"))
    });
  }
}
