import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.target = null;
  }

  load(event) {
    event.preventDefault();
    if (event.target.nodeName == 'A') {
      window.target = event.target.getAttribute('href');
    } else if (event.target.nodeName == 'INPUT') {
      window.targetType = event.target.getAttribute('type');
      window.sourceTarget = event.target;
    } else {
      console.log('Unhandled HTML element');
    }
    window.target = event.target.getAttribute('href');
    let message = this.data.get('confirm');
    if (window.confirm(message)) {
      let scrollHeight = document.querySelector('html').scrollTop;
      let overlay = document.createElement('div');
      overlay.classList.add('case-overlay');
      document.querySelector('html').prepend(overlay);
      overlay.style.top = scrollHeight + 'px';
      overlay.style.background = 'rgba(51, 51, 51, 0.6)'
      overlay.style.height = '100%'
      overlay.style.zIndex = 100000
      document.querySelector('html').style.position = 'relative';
      document.querySelector('body').style.position = 'relative';
      document.querySelector('html').style.overflow = 'hidden';
      document.querySelector('body').style.overflow = 'hidden';
      document.querySelector('html').style.minHeight = '100%';
      document.querySelector('body').style.minHeight = '100%';
      let container = document.createElement('div');
      container.classList.add('container-original');
      let innerDiv = document.createElement('div');
      innerDiv.classList.add('col-sm-12', 'col-md-4', 'col-md-offset-4');
      innerDiv.style.opacity = 1;
      let closeIcon = document.createElement('i');
      closeIcon.classList.add('fas', 'fa-times', 'fa-2x');
      closeIcon.setAttribute('id', 'close_case_page');
      closeIcon.setAttribute('data-controller', 'pin-code');
      closeIcon.setAttribute('data-action', 'click->pin-code#close');
      let caseModal = document.createElement('div');
      caseModal.classList.add('case-modal');
      caseModal.style.textAlign = 'center';
      caseModal.style.width = '100%';
      caseModal.style.minHeight = '230px';
      innerDiv.append(closeIcon);
      innerDiv.append(caseModal);
      container.append(innerDiv);
      overlay.append(container);
      let url = '/admin/admin/pin'
      fetch(url)
      .then(response => response.json())
      .then(data => {
        document.querySelector('.case-modal').innerHTML = data.html;
        document.querySelector('input#pin').focus();
      });
      let modal = document.querySelector('.case-modal');
      let margin = parseInt((window.innerHeight - parseInt(modal.offsetHeight)) / 2);
      modal.style.top = margin + 'px';
      closeIcon.style.top = margin + 'px';

    }
  }

  submit() {
    if (window.attempts == undefined) {
      window.attempts = 0;
    }
    let pin_code = document.querySelector('input#pin').value;
    if (pin_code !== undefined && pin_code != '') {
      let url = '/admin/admin/pin_check'
      url += `?pin=${pin_code}`
      fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.status === 'OK') {
          if (window.target != null) {
            this.close();
            fetch(window.target, {
              method: "POST",
              body: JSON.stringify({
                pin: pin_code
              }),
              headers: {
                "Content-Type": "application/json; charset=UTF-8"
              }
            })
            .then(response => response.json())
            .then(data => {
              if (data.status == 'OK') {
                window.location.replace(data.url);
              } else {
                console.log('Error!!!');
                window.location.replace(data.url);
              }
            });
          } else {
            this.close();
            let checked = window.sourceTarget.checked;
            if (checked == true) {
              window.sourceTarget.checked = false;
            } else {
              window.sourceTarget.checked = true;
            }
            window.sourceTarget.dispatchEvent(new Event('change'));
          }
        } else {
          if (window.attempts < 2) {
            document.querySelector('input#pin').value = '';
            document.querySelector('input#pin').focus();
            window.attempts += 1
          } else {
            this.close();
          }
        }
      });
    } else {
      document.querySelector('input#pin').focus();
    }
  }

  close() {
    document.querySelector('.case-overlay').remove();
    document.querySelector('html').style.backgroundColor = '#fff';
    document.querySelector('body').style.backgroundColor = '#fff';
    document.querySelector('html').style.overflow = 'visible';
    document.querySelector('body').style.overflow = 'visible';
    document.querySelector('html').style.minHeight = 'auto';
    document.querySelector('body').style.minHeight = 'auto';
    window.attempts = null;
  }
}
