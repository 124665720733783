import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ "form" ]

  submit() {
    const form = this.element
    Rails.fire(form, 'submit');
  }
}
