import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["domainsQuery", "mediaQuery", "clearDomainsQuery", "clearMediaQuery"]

  search() {
    if (this.domainsQueryTarget.value.trim() != '' || this.mediaQueryTarget.value.trim() != '') {
      let that = this;
      let url = this.data.get('url').concat('?domains_query=', this.domainsQueryTarget.value, '&media_query=', this.mediaQueryTarget.value);
      const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "X-CSRF-Token": token
        }
      })
      .then(response => response.json())
      .then(data => {
        window.domains_result = data.search.domains;
        window.media_result = data.search.media;
        window.active_domains_search = true;
        window.active_media_search = true;
        window.dispatchEvent(new CustomEvent("campaignContentSearch"));
      });
    }
  }

  submitSearch(event) {
    if (event.keyCode == 13) {
      this.search();
    }
  }

  clear(event) {
    if (event.target == this.clearDomainsQueryTarget) {
      this.domainsQueryTarget.value = null;
      this.mediaQueryTarget.value = null;
      window.domains_result = null;
      window.media_result = null;
      window.active_domains_search = false;
      window.active_media_search = false;
      window.dispatchEvent(new CustomEvent("clearSearchResult"));
    } else {
      this.mediaQueryTarget.value = null;
      window.media_result = null;
      window.active_media_search = false;
      if (this.domainsQueryTarget.value.trim() == '') {
        this.domainsQueryTarget.value = null;
        window.domains_result = null;
        window.active_domains_search = false;
      }
      if (this.domainsQueryTarget.value.trim() != '') {
        this.search();
      } else {
        window.dispatchEvent(new CustomEvent("clearSearchResult"));
      }
    }
  }
}
