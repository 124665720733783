import { SocketUserController } from "./socket_user_controller";
import { Controller } from "@hotwired/stimulus";

export class QuestionGenerationController extends SocketUserController {
  connect() {
    this.create_socket_connection("QuestionGenerationChannel", {});
  }

  generate() {
    event.preventDefault();
    const dialog = document.getElementById("promptDialog");
    dialog.showModal();
  }

  submit() {
    let that = this;
    let url = this.data.get("url");
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    const prompt = document.getElementById("prompt").value;
    const accuracy = false;
    let checked_scopes = [];
    if (document.querySelector("#include_scopes").checked) {
      const scopes = document.querySelectorAll(
        'input[name^="question[func_scope]"]',
      );
      checked_scopes = Array.from(scopes)
        .filter((checkbox) => checkbox.checked)
        .map((checkbox) => checkbox.id.replaceAll("_", " "));
    }

    if (prompt.trim() == "") {
      return false;
    }

    this.show_overlay();

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        prompt: prompt,
        accuracy: accuracy,
        func_scopes: checked_scopes,
      }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "X-CSRF-Token": token,
      },
    });
  }

  on_data(data) {
    this.remove_overlay();
    if (data.error != undefined) {
      alert(data.error);
    } else {
      document.querySelector('input[name="generation_data_id"]').value =
        data.generation_data_id;
      document
        .querySelector("form[data-question-ai-generated-value]")
        .setAttribute("data-question-ai-generated-value", true);
      document.querySelector('[input="description"]').innerHTML = null;
      document.querySelector('[input="description"]').append(data.description);
      document.querySelectorAll('[id^="answer_option_"]').forEach((item, i) => {
        let input = item.querySelector('[id^="options_"]');
        input.value = null;
        input.value = data.answers[i];
        if (data.correct == i + 1) {
          item.querySelector('[id^="answer_"]').checked = true;
        }
      });

      document.querySelector('[input="explanation"]').innerHTML = null;
      document.querySelector('[input="explanation"]').append(data.explanation);
      document.getElementById("prompt").value = null;
    }
  }

  cancel() {
    document.getElementById("prompt").value = null;
  }

  show_overlay() {
    const html = document.querySelector("html");
    const body = document.querySelector("body");
    let overlay = document.createElement("div");
    overlay.classList.add("modal-overlay");
    // let html_text = '<div style="position: absolute; top: 20%; left: calc(50vw - 310px); width: 300px; height: 200px; border: 1px solid green; border-radius: 4px; text-align: center; padding: 10px; color: white; display: inline-block; font-size: 18px;"><strong>Snel</strong></div>'
    // html_text += '<div style="position: absolute; top: 20%; left: calc(50vw + 10px); width: 300px; height: 200px; border: 1px solid white; border-radius: 4px; text-align: center; padding: 10px; color: white; display: inline-block; font-size: 18px;"><strong>Slim</strong></div>'
    let html_text =
      '<i class="fas fa-spinner fa-spin fa-5x" style="position: absolute; top: calc(50vh - 35px); left: calc(50vw - 35px); color: white;"></i>';
    overlay.innerHTML = html_text;
    body.parentNode.insertBefore(overlay, body.nextSibling);
    html.style.overflow = "hidden";
    body.style.overflow = "hidden";
    html.style.height = "100%";
    body.style.height = "100%";
  }

  remove_overlay() {
    const html = document.querySelector("html");
    const body = document.querySelector("body");
    document.querySelector(".modal-overlay").remove();
    html.style.overflow = "visible";
    body.style.overflow = "visible";
    html.style.height = "auto";
    body.style.height = "auto";
  }
}

export default QuestionGenerationController;
