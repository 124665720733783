import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "medium", "question", "search", "button", "clearSearch", "submit", "date", "generate", "noMail", "unlock" ]

  connect() {
    this.random_timestamp = null;
    this.removeId();
  }

  medium() {
    document.querySelector('#question_details').innerHTML = "";
    var url = window.location.href;
    var action = url.split('/').pop();
    if (action.match(/new/)) {
      url = url.replace(action, this.data.get('schedule-id').concat('/' + this.data.get('medium-url')));
    } else {
      url = url.replace(action, this.data.get('medium-url'));
    }
    url += `?medium=${this.mediumTarget.value}`;
    fetch(url)
    .then(response => response.json())
    .then(data => {
      document.querySelector('[name="generated"]').value = 'false';
      this.questionTarget.innerHTML = data.html;
      document.querySelector('#question_details').classList.remove('question-mismatch');
    });
  }

  question() {
    var url = window.location.href;
    var action = url.split('/').pop();
    if (action.match(/new/)) {
      url = url.replace(action, this.data.get('schedule-id').concat('/' + this.data.get('question-url')));
    } else {
      url = url.replace(action, this.data.get('question-url'));
    }
    url += `?question=${this.questionTarget.value}`;
    fetch(url)
    .then(response => response.json())
    .then(data => {
      document.querySelector('[name="generated"]').value = 'false';
      document.querySelector('#question_details').innerHTML = data.html;
      document.querySelector('#question_details').classList.remove('question-mismatch');
      this.clearSearch();
      this.searchTarget.value = this.questionTarget.value;
      if (this.questionTarget.value != "") {
        this.clearSearchTarget.style.display = 'block';
      } else {
        this.clearSearchTarget.style.display = 'none';
      }
      this.submitTarget.focus();
    });
  }

  search(unlock) {
    if (this.searchTarget.value != "") {
      var url = window.location.href;
      var action = url.split('/').pop();
      if (action.match(/new/)) {
        url = url.replace(action, this.data.get('schedule-id').concat('/' + this.data.get('search-url')));
      } else {
        url = url.replace(action, this.data.get('search-url'));
      }
      var date = document.querySelector('#s_date').value;
      url += `?q=${this.searchTarget.value}&date=${date}`;
      if (unlock != undefined && (unlock === 'reactivate' || unlock === 'move')) {
        url += `&unlock=${unlock}`;
      }
      fetch(url)
      .then(response => response.json())
      .then(data => {
        document.querySelector('[name="generated"]').value = 'false';
        if (data.error === null) {
          this.clear_error();
          this.mediumTarget.innerHTML = data.media_options;
          this.mediumTarget.querySelectorAll('option').forEach((item, i) => {
            if (parseInt(item.value) == parseInt(data.medium)) {
              item.setAttribute('selected', '')
            }
          });
          this.questionTarget.innerHTML = data.options;
          this.questionTarget.querySelectorAll('option').forEach((item, i) => {
            if (parseInt(item.value) == parseInt(this.searchTarget.value)) {
              item.setAttribute('selected', '');
              if (unlock === 'reactivate' || unlock == 'move') {
                item.removeAttribute('disabled');
              }
            }
          });
          this.removeId();
          document.querySelector('#question_details').innerHTML = data.html;
          document.querySelector('#question_details').classList.remove('question-mismatch');
        } else {
          this.searchTarget.classList.add('has-error');
          this.buttonTarget.classList.add('has-error');
          document.querySelector('#unlock').setAttribute('data-unlock', data.error_action);
          document.querySelector('#unlock_icon').classList.remove('fa-recycle', 'fa-undo')
          if (data.error_action == 'reactivate' || data.error_action == 'move') {
            if (data.error_action == 'reactivate') {
              document.querySelector('#unlock_icon').classList.add('fa-recycle')
            } else if (data.error_action == 'move') {
              document.querySelector('#unlock_icon').classList.add('fa-undo')
            }
            document.querySelector('#unlock_action').innerHTML = I18n.t(`${data.error_action}_question`);
            document.querySelector('#unlock').style.display = 'block'
          } else {
            document.querySelector('#unlock').style.display = 'none'
          }
          document.querySelector('#error-message').innerHTML = data.error;
          document.querySelector('.schedule-error').style.display = 'block'
        }
      });
    }
  }

  unlock() {
    let mode = this.unlockTarget.getAttribute('data-unlock');
    let answer;
    if (mode == 'reactivate') {
      answer = confirm(I18n.t('confirm_reactivation'));
    } else {
      answer = confirm(I18n.t('confirm_move'));
    }
    if (answer) {
      this.search(mode);
      if (mode == 'move') {
        document.querySelector('input#reschedule').value = 'true';
      }
    } else {
      this.clearSearch();
    }
  }

  clearSearch() {
    this.searchTarget.value = "";
    this.clearSearchTarget.style.display = 'none';
    this.clear_error();
  }

  clear_error() {
    this.searchTarget.classList.remove('has-error');
    this.buttonTarget.classList.remove('has-error');
    document.querySelector('.schedule-error').style.display = 'none';
  }

  submitSearch(event) {
    if (event.keyCode == 13) {
      this.search();
    }
  }

  submit() {
    document.querySelector('form').submit();
  }

  addId() {
    var id = this.questionTarget.value;
    if (id > 0) {
      var selected = this.questionTarget.querySelector(`option[value="${id}"]`);
      var text = selected.innerText;
      selected.innerText = `[${id}] ${text}`;
    }
  }

  removeId() {
    var id = this.questionTarget.value;
    if (id > 0) {
      var selected = this.questionTarget.querySelector(`option[value="${id}"]`);
      var text = selected.innerText;
      selected.innerText = text.replace(/\[\d+\]/, '');
      this.submitTarget.focus();
    }

  }

  random(event) {
    if (this.random_timestamp == null || this.random_timestamp + 1000 < event.timeStamp) {
      this.random_timestamp = event.timeStamp
      var url = window.location.href;
      var action = url.split('/').pop();
      if (action.match(/new/)) {
        url = url.replace(action, this.data.get('schedule-id').concat('/' + this.data.get('random-url')));
      } else {
        url = url.replace(action, this.data.get('random-url'));
      }
      fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.exception != undefined) {
          alert(data.exception);
        } else {
          document.querySelector('[name="generated"]').value = 'true';
          this.clear_error();
          this.mediumTarget.innerHTML = data.media_options;
          this.mediumTarget.querySelectorAll('option').forEach((item, i) => {
            if (parseInt(item.value) == parseInt(data.medium)) {
              item.setAttribute('selected', '')
            }
          });
          this.questionTarget.innerHTML = data.options;
          this.questionTarget.querySelectorAll('option').forEach((item, i) => {
            if (parseInt(item.value) == parseInt(data.question)) {
              item.setAttribute('selected', '');
            }
          });
          this.removeId();
          this.searchTarget.value = data.question;
          document.querySelector('#question_details').innerHTML = data.html;
          document.querySelector('#question_details').classList.remove('question-mismatch');
        }
      });
    }
  }

  noMail() {
    if (this.noMailTarget.checked) {
      this.mediumTarget.setAttribute('disabled', '');
      this.questionTarget.setAttribute('disabled', '');
      this.searchTarget.setAttribute('disabled', '');
      this.buttonTarget.setAttribute('disabled', '');
      this.generateTarget.setAttribute('disabled', '');
    } else {
      this.mediumTarget.removeAttribute('disabled');
      this.questionTarget.removeAttribute('disabled');
      this.searchTarget.removeAttribute('disabled');
      this.buttonTarget.removeAttribute('disabled');
      this.generateTarget.removeAttribute('disabled');
    }
  }
}
