import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle"]

  toggle() {
    let that = this;
    let url = this.data.get('url');
    const shiftPressed = event.altKey || event.metaKey
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    fetch(url, {
      method: "PUT",
      body: JSON.stringify({
        medium_id: this.toggleTarget.value,
        status: this.toggleTarget.checked,
        descendants: !shiftPressed
      }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "X-CSRF-Token": token
      }
    })
    .then(function(response) {
      if (response.ok) {
        if (!shiftPressed) {
          const item = that.element
          const nextElement = item.nextElementSibling;
          if (nextElement && nextElement.getAttribute('data-top-medium') == item.getAttribute('data-top-medium') && nextElement.hasAttribute('data-parent') && nextElement.getAttribute('data-parent') == item.getAttribute('data-medium-id')) {
            for (let nextChild = item.nextElementSibling; nextChild.getAttribute('data-nested-collapse-level') > item.getAttribute('data-nested-collapse-level'); nextChild = nextChild.nextElementSibling) {
              nextChild.querySelector('input[type="checkbox"]').checked = that.toggleTarget.checked
            }
          }
        }
        window.dispatchEvent(new CustomEvent("mediumToggle", {detail: {domain: that.data.get('domain'), status: that.toggleTarget.checked}}))
      }
    });
  }

  update(event) {
    if (event.detail.domain == this.data.get('domain')) {
      if (event.detail.status == true) {
        this.toggleTarget.checked = true
      } else {
        this.toggleTarget.checked = false
      }
    }
  }

  all_media(event) {
    if (event.detail.status == true) {
      this.toggleTarget.checked = true
    } else {
      this.toggleTarget.checked = false
    }
  }
}
