import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['email']
  static values = {
    locale: String
  }

  check() {
    const that = this;
    const VALID_EMAIL_REGEX = /[\w+\-.]+@[a-z\d\-]+(\.[a-z]+)*\.[a-z]{2,}/i;
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    if (this.emailTarget.value.match(VALID_EMAIL_REGEX)) {
      let email = this.emailTarget.value;
      fetch('/sso', {
        method: "POST",
        body: JSON.stringify({
          email: email
        }),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "X-CSRF-Token": token
        }
      })
      .then(response => response.json())
      .then(data => {
        if (data.sso) {
          let regular_fields = document.querySelectorAll('#step-1 .regular');
          regular_fields.forEach((item, i) => {
            item.style.display = 'none';
          });
          document.getElementById('links').style.display = 'none';
          let sso_fields = document.querySelectorAll('#step-1 .sso');
          sso_fields.forEach((item, i) => {
            item.style.display = 'block';
          });
          let button = document.getElementById('sso_redirect');
          let url = '/saml/init?idp=' + data.idp
          button.setAttribute('href', url)
          if (data.locale != that.localeValue) {
            that.localeValue = data.locale;
            let available = document.getElementById('sso_available');
            let remember_me = document.querySelector('label[for="user_remember_me"]');
            let tooltip = document.getElementById('remember_me_tooltip');
            available.innerHTML = '<i class="fas fa-lock"></i> ' + I18n.t('single_sign_on_enabled', {locale: data.locale});
            remember_me.innerText = I18n.t('remember_me', {locale: data.locale});
            tooltip.setAttribute('data-original-title', I18n.t('remember_me_explanation', {locale: data.locale}));
            button.innerText = I18n.t('continue', {locale: data.locale});
          }
        }
      })
    } else {
      let regular_fields = document.querySelectorAll('#step-1 .regular');
      regular_fields.forEach((item, i) => {
        item.style.display = 'block';
      });
      document.getElementById('links').style.display = 'block';
      let sso_fields = document.querySelectorAll('#step-1 .sso');
      sso_fields.forEach((item, i) => {
        item.style.display = 'none';
      });
      let button = document.getElementById('sso_redirect');
      button.setAttribute('href', null)
    }
  }
}
