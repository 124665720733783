import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source", "all", "groupsQuery", "usersQuery", "toggle" ]

  update() {
    var url = window.location.href;
    var action = url.split('/').pop();
    var id = this.sourceTarget.value;
    url = url.replace(action, this.data.get('url'));
    url += `?group_id=${id}&status=${this.sourceTarget.checked}`;
    if (this.activeSearch()) {
      var groupsQuery = document.querySelector('#groups_query');
      var usersQuery  = document.querySelector('#group_users_query');

      url += `&groups_query=${groupsQuery.value}&users_query=${usersQuery.value}`;
    }
    if (this.sourceTarget.checked == true) {
      fetch(url)
      .then(response => response.json())
      .then(data => {
        this.insertGroup(id, data)
      });
    } else {
      fetch(url)
      .then(
        this.removeGroup(id)
      );
    }
    this.checkSelection();
  }

  update_all() {
    var url = window.location.href;
    var action = url.split('/').pop();
    var id = this.allTarget.value;
    url = url.replace(action, this.data.get('url'));
    url += `?status=${this.allTarget.checked}`;
    if (this.activeSearch()) {
      var groupsQuery = document.querySelector('#groups_query');
      var usersQuery  = document.querySelector('#group_users_query');

      url += `&groups_query=${groupsQuery.value}&users_query=${usersQuery.value}`;
    }
    if (this.allTarget.checked == true) {
      fetch(url)
      .then(response => response.json())
      .then(data => {
        this.insertAll(data)
      });
    } else {
      fetch(url)
      .then(
        this.removeAll()
      );
    }
    this.checkSelection();
  }

  insertGroup(id, data) {
    document.querySelector(`#table_1 [data-group-id="${id}"]`).setAttribute('data-selected', '');

    var selection = document.querySelectorAll('#table_2 .table-div [data-group-header]')
    if (selection.length > 0) {
      var inserted = false;
      selection.forEach((item, i) => {
        if (!inserted && parseInt(item.getAttribute('data-index')) > parseInt(data.index)) {
          item.insertAdjacentHTML('beforebegin', data.html)
          inserted = true
        }
        if (inserted == false) {
          document.querySelector("#table_2 .table-div").insertAdjacentHTML('beforeend', data.html)
          inserted = true
        }
      });
    } else {
      if (document.querySelector("#table_2 .empty-flip-table")) {
        document.querySelector("#table_2 .empty-flip-table").style.display = 'none';
      }
      document.querySelector("#table_2 .table-div").insertAdjacentHTML('beforeend', data.html)
    }

    if (this.activeSearch()) {
      var users_mode = document.querySelector('#users-toggle').getAttribute('data-mode');
      if (users_mode == 'list') {
        var header = document.querySelector(`#table_2 [data-group-header="${id}"]`);
        if (parseInt(header.getAttribute('data-selected-query-result')) > 0) {
          header.classList.remove('hidden_row');
        } else {
          header.classList.add('hidden_row');
        }

        document.querySelectorAll(`#table_2 [data-group-id="${id}"][data-user-id]`).forEach((item, i) => {
          if (item.getAttribute('data-selected') == undefined || item.getAttribute('data-query-result') == undefined) {
            item.classList.add('hidden_row');
          } else {
            item.classList.remove('hidden_row');
          }
        });
      }
    }

    this.showUsers()
  }

  removeGroup(id) {
    document.querySelector(`#table_1 [data-group-id="${id}"]`).removeAttribute('data-selected');

    var container = document.querySelector("#table_2");
    container.querySelectorAll(`[data-group-id="${id}"], [data-group-header="${id}"]`).forEach((item, i) => {
      item.remove()
    });
  }

  insertAll(data) {
    if (this.activeSearch()) {
      var groups = document.querySelectorAll('#table_1 [data-group-id][data-query-result]');
    } else {
      var groups = document.querySelectorAll('#table_1 [data-group-id]');
    }

    groups.forEach((item, i) => {
      item.querySelector('.checkboxRG input').checked = true;
      item.setAttribute('data-selected', '');
    });

    this.checkSelection()

    // Insert all users in table_2
    document.querySelector("#table_2 .table-div").innerHTML = data.html

    this.showUsers()
  }

  removeAll() {
    if (this.activeSearch()) {
      var groups = document.querySelectorAll('#table_1 [data-group-id][data-query-result]');
    } else {
      var groups = document.querySelectorAll('#table_1 [data-group-id]');
    }

    groups.forEach((item, i) => {
      item.querySelector('.checkboxRG input').checked = false;
      item.removeAttribute('data-selected');
    });

    document.querySelector("#table_2 .table-div").innerHTML = ""
    document.querySelector('#all_groups').checked = false
  }

  checkSelection() {
    if (this.activeSearch()) {
      var groups = document.querySelectorAll('#table_1 [data-query-result] [id^="campaign_groups_"]');
    } else {
      var groups = document.querySelectorAll('#table_1 [id^="campaign_groups_"]');
    }

    var total = 0;
    var selected = 0;

    groups.forEach((item, i) => {
      total += 1;
      if (item.checked == true) {
        selected += 1
      }
    });

    if (total > 0 && selected == total) {
      document.querySelector('#all_groups').checked = true
    } else {
      document.querySelector('#all_groups').checked = false
    }
  }

  checkUsersSelection() {
    var total = 0;
    var selected = 0;
    if (this.activeSearch()) {
      var users = document.querySelectorAll('#table_2 [data-query-result] [id^="campaign_group-users"]')
    } else {
      var users = document.querySelectorAll('#table_2 [id^="campaign_group-users"]')
    }

    users.forEach((item, i) => {
      total += 1;
      if (item.checked == true) {
        selected += 1
      }
    });

    if (total > 0 && selected == total) {
      document.querySelector('#all_groups_and_users').checked = true
    } else {
      document.querySelector('#all_groups_and_users').checked = false
    }
  }

  activeSearch() {
    var groupsQuery = document.querySelector('#groups_query');
    var usersQuery  = document.querySelector('#group_users_query');

    return Boolean(groupsQuery.value.trim() || usersQuery.value.trim());
  }

  toggle() {
    var mode = this.toggleTarget.getAttribute('data-mode');

    if (mode == 'list') {
      this.toggleTarget.setAttribute('data-mode', 'edit');
      this.toggleTarget.innerText = 'Done';
      document.querySelector('#all_groups').parentElement.style.visibility = 'visible';
    } else {
      this.toggleTarget.setAttribute('data-mode', 'list');
      this.toggleTarget.innerText = 'Edit';
      document.querySelector('#all_groups').parentElement.style.visibility = 'hidden';
    }

    this.showGroups();
    this.checkSelection();
  }

  showGroups() {
    if (this.activeSearch()) {
      this.showSearchResult();
    } else {
      var groups_mode = document.querySelector('#groups-toggle').getAttribute('data-mode');
      var all_groups  = document.querySelectorAll('#table_1 [data-group-id]');

      all_groups.forEach((item, i) => {
        item.classList.add('hidden_row');
      });

      if (groups_mode == 'list') {
        var groups = document.querySelectorAll('#table_1 [data-group-id][data-selected]');

        document.querySelectorAll(`#table_1 .checkboxRG input`).forEach((item, i) => {
          item.setAttribute('disabled', 'disabled');
        });
      } else {
        var groups = document.querySelectorAll('#table_1 [data-group-id]');

        document.querySelectorAll(`#table_1 .checkboxRG input`).forEach((item, i) => {
          item.removeAttribute('disabled');
        });
      }

      groups.forEach((item, i) => {
        item.classList.remove('hidden_row');
      });
    }
  }

  showSearchResult() {
    var groups_mode = document.querySelector('#groups-toggle').getAttribute('data-mode');
    var all_groups  = document.querySelectorAll('#table_1 [data-group-id]');

    all_groups.forEach((item, i) => {
      item.classList.add('hidden_row');
    });

    if (groups_mode == 'list') {
      var groups = document.querySelectorAll('#table_1 [data-group-id][data-query-result][data-selected]');

      document.querySelectorAll(`#table_1 .checkboxRG input`).forEach((item, i) => {
        item.setAttribute('disabled', 'disabled');
      });
    } else {
      var groups = document.querySelectorAll('#table_1 [data-group-id][data-query-result]');

      document.querySelectorAll(`#table_1 .checkboxRG input`).forEach((item, i) => {
        item.removeAttribute('disabled');
      });
    }

    groups.forEach((item, i) => {
      item.classList.remove('hidden_row');
    });
  }

  showUsers() {
    if (this.activeSearch()) {
      this.showUsersSearchResult();
    } else {
      var mode = document.querySelector('#users-toggle').getAttribute('data-mode');
      var all_headers = document.querySelectorAll('#table_2 [data-group-header]');
      var all_users   = document.querySelectorAll('#table_2 [data-user-id]');

      all_headers.forEach((item, i) => {
        item.classList.add('hidden_row');
      });

      all_users.forEach((item, i) => {
        item.classList.add('hidden_row');
      });

      if (mode == 'list') {
        var headers = document.querySelectorAll('#table_2 [data-group-header][data-visible]');
        var users   = document.querySelectorAll('#table_2 [data-user-id][data-selected]');

        document.querySelectorAll(`#table_2 .checkboxRG input`).forEach((item, i) => {
          item.setAttribute('disabled', 'disabled');
        });
      } else {
        var headers = document.querySelectorAll('#table_2 [data-group-header]');
        var users   = document.querySelectorAll('#table_2 [data-user-id]');

        document.querySelectorAll(`#table_2 .checkboxRG input`).forEach((item, i) => {
          item.removeAttribute('disabled');
        });
      }

      headers.forEach((item, i) => {
        item.classList.remove('hidden_row');
      });

      users.forEach((item, i) => {
        item.classList.remove('hidden_row');
      });
    }
  }

  showUsersSearchResult() {
    var mode        = document.querySelector('#users-toggle').getAttribute('data-mode');
    var all_headers = document.querySelectorAll('#table_2 [data-group-header]');
    var all_users   = document.querySelectorAll('#table_2 [data-user-id]');

    all_headers.forEach((item, i) => {
      item.classList.add('hidden_row');
    });

    all_users.forEach((item, i) => {
      item.classList.add('hidden_row');
    });

    if (mode == 'list') {
      var headers = [];
      document.querySelectorAll('#table_2 [data-group-header][data-selected-query-result]').forEach((item, i) => {
        if (parseInt(item.getAttribute('data-selected-query-result')) > 0) {
          headers.push(item);
        }
      });
      var users   = document.querySelectorAll('#table_2 [data-user-id][data-query-result][data-selected]');

      document.querySelectorAll(`#table_2 .checkboxRG input`).forEach((item, i) => {
        item.setAttribute('disabled', 'disabled');
      });
    } else {
      var headers = document.querySelectorAll('#table_2 [data-group-header][data-selected-query-result]');
      var users   = document.querySelectorAll('#table_2 [data-user-id][data-query-result]');

      document.querySelectorAll(`#table_2 .checkboxRG input`).forEach((item, i) => {
        item.removeAttribute('disabled');
      });
    }

    headers.forEach((item, i) => {
      item.classList.remove('hidden_row');
    });

    users.forEach((item, i) => {
      item.classList.remove('hidden_row');
    });
  }
}
