import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle", "count"]
  static values = { count: Number }

  toggle() {
    // TODO: Include domains_query and media_query
    let that = this;
    let url = this.data.get('url');
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    fetch(url, {
      method: "PUT",
      body: JSON.stringify({
        domain_id: this.toggleTarget.value,
        status: this.toggleTarget.checked
      }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "X-CSRF-Token": token
      }
    })
    .then(function(response) {
      if (response.ok) {
        window.dispatchEvent(new CustomEvent("domainToggle", {detail: {domain: that.data.get('domain'), status: that.toggleTarget.checked}}))
        if (that.toggleTarget.checked) {
          let count = document.querySelectorAll(`[data-medium-select-domain="${that.toggleTarget.value}"]`).length
          that.countTarget.setAttribute('data-domain-media-select-count-value', count)
          that.countTarget.innerHTML = count
        } else {
          that.countTarget.setAttribute('data-domain-media-select-count-value', 0)
          that.countTarget.innerHTML = 0
        }
        that.element.dispatchEvent(new CustomEvent("selectionToggle", {detail: {domain: that.data.get('domain')}, bubbles: true}));
      }
    });
  }

  update(event) {
    if (event.detail.domain == this.data.get('domain')) {
      let totalCount = document.querySelectorAll(`[data-medium-select-domain="${this.toggleTarget.value}"]`).length
      let updatedCount = document.querySelectorAll(`[data-medium-select-domain="${this.toggleTarget.value}"] input[type="checkbox"]:checked`).length
      this.countTarget.setAttribute('data-domain-media-select-count-value', updatedCount)
      this.countTarget.innerHTML = updatedCount
      if ((updatedCount) == totalCount) {
        this.toggleTarget.checked = true
      } else {
        this.toggleTarget.checked = false
      }
      this.element.dispatchEvent(new CustomEvent("selectionToggle", {detail: {domain: this.data.get('domain')}, bubbles: true}));
    }
  }

  all_media(event) {
    if (event.detail.status == true) {
      this.toggleTarget.checked = true
      let totalCount = document.querySelectorAll(`[data-medium-select-domain="${this.toggleTarget.value}"]`).length
      this.countTarget.setAttribute('data-domain-media-select-count-value', totalCount)
      this.countTarget.innerHTML = totalCount
    } else {
      this.toggleTarget.checked = false
      this.countTarget.setAttribute('data-domain-media-select-count-value', 0)
      this.countTarget.innerHTML = 0
    }
  }
}
