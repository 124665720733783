import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    "ai-generated": Boolean,
    "ai-validation": String,
    "validator-count": Number,
    quality: String,
    "quality-check": String,
  };

  static targets = ["dialog", "submit", "validation"];

  dialog = document.getElementById("quality_check");
  continue_button = this.dialog.querySelector("#continue");
  update_button = this.dialog.querySelector("#update");
  close_button = this.dialog.querySelector(".close");
  validation_dialog = document.getElementById("validation");
  send_button = this.validation_dialog.querySelector("#send");
  bypass_button = this.validation_dialog.querySelector("#bypass");
  validation_close_button = this.validation_dialog.querySelector(".close");

  connect() {
    this.dialog.addEventListener("close", (event) => {
      if (this.dialog.returnValue != "") {
        this.submit();
      }
    });

    this.continue_button.addEventListener("click", (event) => {
      event.preventDefault();
      this.dialog.close(
        document.querySelector('input[name="quality_check_data_id"]').value,
      );
    });

    this.update_button.addEventListener("click", (event) => {
      event.preventDefault();
      this.dialog.close();
    });

    this.close_button.addEventListener("click", (event) => {
      event.preventDefault();
      this.dialog.close();
    });

    this.send_button.addEventListener("click", (event) => {
      event.preventDefault();
      this.validation_dialog.close();
      this.validationTarget.value = true;
      document.querySelector('form[data-controller="question"]').submit();
    });

    this.bypass_button.addEventListener("click", (event) => {
      event.preventDefault();
      this.validation_dialog.close();
      this.validationTarget.value = false;
      document.querySelector('form[data-controller="question"]').submit();
    });

    this.validation_close_button.addEventListener("click", (event) => {
      event.preventDefault();
      this.validation_dialog.close();
    });
  }

  submit(event) {
    if (event) {
      event.preventDefault();
    }
    let errors = [];
    let counter = 0;
    document.querySelectorAll('[id^="answer_option_"]').forEach((item) => {
      let option = item.querySelector("input");
      if (option.value.trim() != "") {
        counter += 1;
      }
    });

    if (counter < 2) {
      errors.push("too_few_answer_options");
    }

    let answer = document.querySelector('[name="answer"]:checked');

    if (answer == null) {
      errors.push("no_correct_answer_selected");
    } else if (
      answer.parentNode.parentNode
        .querySelector('[id^="options_"]')
        .value.trim() == ""
    ) {
      errors.push("no_valid_answer_selected");
    } else {
      answer = answer.value;
    }

    if (document.querySelector("#qtype").value == "1") {
      if (errors.indexOf("too_few_answer_options") != -1) {
        alert(I18n.t("errors.questions.answer"));
        return 0;
      } else if (errors.indexOf("no_correct_answer_selected") != -1) {
        alert(I18n.t("select_correct_answer"));
        return 0;
      } else if (errors.indexOf("no_valid_answer_selected") != -1) {
        alert(I18n.t("select_valid_answer"));
        return 0;
      }

      if (window.aiFeaturesEnabled == "true") {
        if (
          this.qualityCheckValue == "shown" &&
          (this.qualityValue == "" || this.qualityValue == undefined)
        ) {
          this.show_overlay();
          let url = "/admin/question_quality_check/new";
          const token = document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content");
          let description = document.querySelector("input#description").value;
          let answer_options = [];
          document
            .querySelectorAll('input[id^="options_"]')
            .forEach((item, i) => {
              answer_options.push(i + 1 + ": " + item.value);
            });
          let explanation = document.querySelector("input#explanation").value;
          fetch(url, {
            method: "POST",
            body: JSON.stringify({
              description: description,
              answer_options: answer_options,
              correct_answer: answer,
              explanation: explanation,
            }),
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              "X-CSRF-Token": token,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              this.remove_overlay();
              if (data.error != undefined) {
                this.qualityValue = data.quality;
                this.submit();
              } else {
                document.querySelector(
                  'input[name="quality_check_data_id"]',
                ).value = data.quality_check_data_id;
                this.qualityValue = data.quality;
                this.dialog.querySelector(".modal-body").innerHTML = data.html;
                this.dialog
                  .querySelector(".modal-body #hide_quality_check")
                  .addEventListener("change", (event) => {
                    event.preventDefault();
                    this.hide_quality_check_modal();
                  });
                this.dialog.showModal();
              }
            });
        } else if (
          this.validatorCountValue > 0 &&
          this.aiGeneratedValue &&
          this.aiValidationValue != "always"
        ) {
          if (this.aiValidationValue == "always") {
            this.validationTarget.value = true;
            document.querySelector('form[data-controller="question"]').submit();
          } else if (this.aiValidationValue == "ask") {
            this.validation_dialog.showModal();
          } else {
            this.validationTarget.value = false;
            document.querySelector('form[data-controller="question"]').submit();
          }
        } else {
          if (
            this.validatorCountValue > 0 &&
            this.aiGeneratedValue &&
            this.aiValidationValue == "always"
          ) {
            this.validationTarget.value = true;
          }
          document.querySelector('form[data-controller="question"]').submit();
        }
      } else {
        document.querySelector('form[data-controller="question"]').submit();
      }
    } else {
      document.querySelector('form[data-controller="question"]').submit();
    }
  }

  reset_quality() {
    this.qualityValue = "";
  }

  hide_quality_check_modal() {
    let url = "/admin/question_quality_check/hide";
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        show_quality_check_modal: false,
      }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "X-CSRF-Token": token,
      },
    });
    this.qualityCheckValue = "hidden";
  }

  show_overlay() {
    const html = document.querySelector("html");
    const body = document.querySelector("body");
    let overlay = document.createElement("div");
    overlay.classList.add("modal-overlay");
    // let html_text = '<div style="position: absolute; top: 20%; left: calc(50vw - 310px); width: 300px; height: 200px; border: 1px solid green; border-radius: 4px; text-align: center; padding: 10px; color: white; display: inline-block; font-size: 18px;"><strong>Snel</strong></div>'
    // html_text += '<div style="position: absolute; top: 20%; left: calc(50vw + 10px); width: 300px; height: 200px; border: 1px solid white; border-radius: 4px; text-align: center; padding: 10px; color: white; display: inline-block; font-size: 18px;"><strong>Slim</strong></div>'
    let html_text =
      '<i class="fas fa-spinner fa-spin fa-5x" style="position: absolute; top: calc(50vh - 35px); left: calc(50vw - 35px); color: white;"></i>';
    overlay.innerHTML = html_text;
    body.parentNode.insertBefore(overlay, body.nextSibling);
    html.style.overflow = "hidden";
    body.style.overflow = "hidden";
    html.style.height = "100%";
    body.style.height = "100%";
  }

  remove_overlay() {
    const html = document.querySelector("html");
    const body = document.querySelector("body");
    document.querySelector(".modal-overlay").remove();
    html.style.overflow = "visible";
    body.style.overflow = "visible";
    html.style.height = "auto";
    body.style.height = "auto";
  }
}
