import PreviewController from "./preview_controller";

export default class extends PreviewController {
    connect() {
        super.connect()

        const answers = parseInt(document.getElementById('label-1').children[0].innerHTML)
        const correct = parseInt(document.getElementById('label-2').children[0].innerHTML)


        const total_chart = '.dchart-area'
        window[total_chart] = [answers, correct]
        redgrasp.draw_donut_chart(total_chart, total_chart, 150, 150)

        const leaders = '.dchart2-area'
        window[leaders] = []

        for (let i = 3; i <= 5; i++) {
            const children = document.getElementById('label-' + i).children

            if (children.length > 0) {
                window[leaders].push(parseInt(children[0].innerHTML.replaceAll(',', '')))
            }
        }
        redgrasp.draw_donut_chart(leaders, leaders, 150, 150)
    }
}
