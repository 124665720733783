import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    handle_join(res) {
        if (res['lq_session'] && res['quiz_id']) {
            let url = window.location.href
            const action = url.split('/').pop()
            url = url.replace(action, 'live_quizzes/' + res['quiz_id'] + '/participate?lq_session=' + res['lq_session'])
            window.location.href = url
        } else {
            console.log('Join failed')
        }
    }

    submit(event) {
        const hash_input = document.querySelector('[name="hash"]'); //event.target.parentNode.childNodes[3];
        const name_input = document.querySelector('[name="name"]'); //event.target.parentNode.childNodes[7]

        if (window.lq_session === undefined) {
            let url = window.location.href
            const action = url.split('/').pop()
            url = url.replace(action, 'live/join')
            url += '?user_name=' + name_input.value
            if (url.indexOf('join_hash') === -1) {
                url = url.replace('&', '?');
                url += '&join_hash=' + hash_input.value
            }
            const that = this;
            fetch(url)
                .then(res => res.text())
                .then(res => JSON.parse(res.toString()))
                .then(res => that.handle_join(res))
        }
    }

    connect() {
      console.log('Alive!!')
    }
}
