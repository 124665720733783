import { Controller } from "@hotwired/stimulus"
import consumer from '../channels/consumer'
import { Subscription } from "@rails/actioncable/src";

export class SocketUserController extends Controller {
    HEARTBEAT_INTERVAL = 15000

    create_socket_connection(channel, args) {
        const that = this

        const params = typeof channel === "object" ? channel : {channel}

        const cookies = args !== undefined ? args : {}
        const raw_cookies = document.cookie.split('; ')

        for (let i = 0; i < raw_cookies.length; i++) {
            const parts = raw_cookies[i].split('=');
            cookies[parts[0]] = parts[1];
        }

        if (typeof channel !== "object") {
            Object.assign(params, cookies)
        }

        const mixin = {
            received(data) {
                that.on_data(data)
            },
            connected() {
                that.on_connect();
            },
            disconnected() {
                that.on_disconnect()
            },
            rejected() {
                that.on_disconnect();
            }
        }

        const subscription = new Subscription(consumer, params, mixin)
        this.channel = consumer.subscriptions.add(subscription)
    }

    on_data(data) {
        if (data === undefined || data['action'] !== 'alive') return

        if (data['alive']) {
            window['heartbeat_counter']++
            this.schedule_heartbeat()
        }
    }

    on_connect() {
        window['heartbeat_counter'] = 0
        this.schedule_heartbeat()
    }

    schedule_heartbeat() {
        const that = this;

        clearTimeout(window['heartbeat_timer'])

        window['heartbeat_timer'] = setTimeout(() => {
            that.transmit('heartbeat')
        }, that.HEARTBEAT_INTERVAL)
    }

    transmit(action, parameters) {
        this.channel.perform(action, parameters !== undefined ? parameters : {})
    }
}

export default SocketUserController;
