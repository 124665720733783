// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.

import { createConsumer } from "@rails/actioncable"

function createConsumerWrapper() {
    return createConsumer();
}

export default createConsumerWrapper()
