import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle", "switch"]

  connect() {
    let that = this;
    let url = window.location.href
    let action = url.split('/').pop()
    url = url.replace(action, 'content/index')
    fetch(url)
    .then(response => response.json())
    .then(data => {
      let container = document.querySelector('.table-container#table_1');
      // Reset vertical-align
      container.style.textAlign = 'left';
      container.style.top = 0;
      container.style.webkitTransform = 'translateY(0)';
      container.style.msTransform = 'translateY(0)';
      container.style.transform = 'translateY(0)';
      document.querySelector('#table_1').innerHTML = data.html;
      that.list_view();
    });
  }

  toggle() {
    // TODO: Include domains_query and media_query
    let that = this;
    let url = this.data.get('url');
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    fetch(url, {
      method: "PUT",
      body: JSON.stringify({
        status: this.toggleTarget.checked
      }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "X-CSRF-Token": token
      }
    })
    .then(response => response.json())
    .then(data => {
      document.querySelectorAll('#table_1 .checkboxRG input[type="checkbox"]').forEach((item, i) => {
        item.checked = that.toggleTarget.checked;
      });
      document.querySelector('#table_2').innerHTML = data.html
      window.dispatchEvent(new CustomEvent("initialLoad", {detail: {mode: 'addDomain'}}))
    });
  }

  switch() {
    let mode = this.data.get('mode');
    if (mode == 'list') {
      this.toggleTarget.parentElement.style.visibility = 'visible';
      this.switchTarget.innerText = I18n.t('done');
      this.data.set('mode', 'edit');
      this.edit_view();
    } else {
      this.toggleTarget.parentElement.style.visibility = 'hidden';
      this.switchTarget.innerText = I18n.t('select');
      this.data.set('mode', 'list');
      this.list_view();
    }
  }

  list_view() {
    document.querySelectorAll('#table_1 .checkboxRG input[type="checkbox"]').forEach((item, i) => {
      item.setAttribute('disabled', '');
      let row = item.parentElement.parentElement.parentElement;
      if (item.checked) {
        if (!window.active_domains_search || (window.active_domains_search && window.domains_result.indexOf(parseInt(item.value)) != -1)) {
          row.style.display = 'table-row';
        } else {
          row.style.display = 'none';
        }
      } else {
        row.style.display = 'none';
      }
    });
    let selected_domains = document.querySelectorAll('#table_1 .checkboxRG input[type="checkbox"]:checked');
    if (selected_domains.length > 0) {
      document.querySelector('#no_domains_selected').style.display = 'none'
    } else {
      document.querySelector('#no_domains_selected').style.display = 'block'
    }
  }

  edit_view() {
    document.querySelectorAll('#table_1 .checkboxRG input[type="checkbox"]').forEach((item, i) => {
      item.removeAttribute('disabled');
      let row = item.parentElement.parentElement.parentElement;
      if (!window.active_domains_search || (window.active_domains_search && window.domains_result.indexOf(parseInt(item.value)) != -1)) {
        row.style.display = 'table-row';
      } else {
        row.style.display = 'none';
      }
    });
  }

  show_search_result() {
    let mode = this.data.get('mode');
    if (mode == 'list') {
      this.list_view();
    } else {
      this.edit_view();
    }
  }

  clear_search_result() {
    let mode = this.data.get('mode');
    if (mode == 'list') {
      this.list_view();
    } else {
      this.edit_view();
    }
  }
}
