import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["groups", "users", "resetGroupsQuery", "resetUsersQuery"]

  search() {
    this.clearSearchResult();
    if (this.groupsTarget.value.trim() || this.usersTarget.value.trim()) {
      var url = window.location.href;
      var action = url.split('/').pop();
      url = url.replace(action, this.data.get('url'));
      url += `?groups_query=${this.groupsTarget.value}&users_query=${this.usersTarget.value}`;
      fetch(url)
      .then(response => response.json())
      .then(data => {
        window.search_result = data.search;
        this.markSearchResult(data);
        this.showSearchResult();
      });
    }
  }

  markSearchResult(data) {
    var users  = data.search.users
    var stats  = data.search.group_data

    Object.keys(stats).forEach((id, i) => {
      if (stats[id]['query_result'] > 0) {
        var header = document.querySelector(`#table_1 [data-group-id="${id}"]`);
        header.setAttribute('data-query-result', '');

        var header = document.querySelector(`#table_2 [data-group-header="${id}"]`);
        if (header) {
          header.setAttribute('data-query-result', '');
          header.setAttribute('data-selected-query-result', stats[id]['selected_query_result']);
        }
      }
    });

    users.forEach((id, i) => {
      var row = document.querySelector(`#table_2 [data-user-id="${id}"]`);
      if (row) {
        row.setAttribute('data-query-result', '');
      }
    });
  }

  showSearchResult() {
    var groups_mode = document.querySelector('#groups-toggle').getAttribute('data-mode');
    var all_groups  = document.querySelectorAll('#table_1 [data-group-id]');

    all_groups.forEach((item, i) => {
      item.classList.add('hidden_row');
    });

    if (groups_mode == 'list') {
      var groups = document.querySelectorAll('#table_1 [data-group-id][data-query-result][data-selected]');

      document.querySelectorAll(`#table_1 .checkboxRG input`).forEach((item, i) => {
        item.setAttribute('disabled', 'disabled');
      });
    } else {
      var groups = document.querySelectorAll('#table_1 [data-group-id][data-query-result]');

      document.querySelectorAll(`#table_1 .checkboxRG input`).forEach((item, i) => {
        item.removeAttribute('disabled');
      });
    }

    groups.forEach((item, i) => {
      item.classList.remove('hidden_row');
    });

    var users_mode  = document.querySelector('#users-toggle').getAttribute('data-mode');
    var all_headers = document.querySelectorAll('#table_2 [data-group-header]');
    var all_users   = document.querySelectorAll('#table_2 [data-user-id]');

    all_headers.forEach((item, i) => {
      item.classList.add('hidden_row');
    });

    all_users.forEach((item, i) => {
      item.classList.add('hidden_row');
    });

    if (users_mode == 'list') {
      var headers = [];
      document.querySelectorAll('#table_2 [data-group-header][data-selected-query-result]').forEach((item, i) => {
        if (parseInt(item.getAttribute('data-selected-query-result')) > 0) {
          headers.push(item);
        }
      });
      var users   = document.querySelectorAll('#table_2 [data-user-id][data-query-result][data-selected]');
    } else {
      var headers = document.querySelectorAll('#table_2 [data-group-header][data-selected-query-result]');
      var users   = document.querySelectorAll('#table_2 [data-user-id][data-query-result]');
    }

    headers.forEach((item, i) => {
      item.classList.remove('hidden_row');
    });

    users.forEach((item, i) => {
      item.classList.remove('hidden_row');
    });

    this.checkSelection();
  }

  clear() {
    this.clearQuery();
    this.clearSearchResult();
  }

  clearQuery() {
    this.groupsTarget.value = '';
    this.resetGroupsQueryTarget.style.display = 'none';
    this.usersTarget.value  = '';
    this.resetUsersQueryTarget.style.display = 'none';
  }

  clearSearchResult() {
    var groups  = document.querySelectorAll('#table_1 [data-group-id]');
    var headers = document.querySelectorAll('#table_2 [data-group-header][data-query-result]');
    var users   = document.querySelectorAll('#table_2 [data-user-id][data-query-result]');

    groups.forEach((item, i) => {
      item.removeAttribute('data-query-result');
    });

    headers.forEach((item, i) => {
      item.removeAttribute('data-query-result');
      item.removeAttribute('data-selected-query-result');

      if (item.getAttribute('data-visible') == undefined) {
        var group = item.getAttribute('data-group-header');
        var selected = document.querySelectorAll(`#table_2 [data-group-id="${group}"][data-selected]`);
        if (selected.length > 0) {
          item.setAttribute('data-visible', '');
        }
      }
    });

    users.forEach((item, i) => {
      item.removeAttribute('data-query-result');
    });

    this.showGroupsAndUsers();
  }

  showGroupsAndUsers() {
    var groups_mode = document.querySelector('#groups-toggle').getAttribute('data-mode');
    var all_groups = document.querySelectorAll('#table_1 [data-group-id]');

    if (groups_mode == 'list') {
      var groups = document.querySelectorAll('#table_1 [data-group-id][data-selected]');

      document.querySelectorAll(`#table_1 .checkboxRG input`).forEach((item, i) => {
        item.setAttribute('disabled', 'disabled');
      });
    } else {
      var groups = document.querySelectorAll('#table_1 [data-group-id]');

      document.querySelectorAll(`#table_1 .checkboxRG input`).forEach((item, i) => {
        item.removeAttribute('disabled');
      });
    }

    groups.forEach((item, i) => {
      item.classList.remove('hidden_row');
    });

    var users_mode = document.querySelector('#users-toggle').getAttribute('data-mode');

    if (users_mode == 'list') {
      var headers = document.querySelectorAll('#table_2 [data-group-header][data-visible]');
      var users   = document.querySelectorAll('#table_2 [data-user-id][data-selected]');
    } else {
      var headers = document.querySelectorAll('#table_2 [data-group-header]');
      var users   = document.querySelectorAll('#table_2 [data-user-id]');
    }

    headers.forEach((item, i) => {
      item.classList.remove('hidden_row');
    });

    users.forEach((item, i) => {
      item.classList.remove('hidden_row');
    });

    this.checkSelection();
  }

  submit(event) {
    if (event.keyCode == 13) {
      this.search();
    }
  }

  activeSearch() {
    return Boolean(this.groupsTarget.value.trim() || this.usersTarget.value.trim());
  }

  checkSelection() {
    if (this.activeSearch()) {
      var groups = document.querySelectorAll('#table_1 [data-query-result] [id^="campaign_groups_"]');
    } else {
      var groups = document.querySelectorAll('#table_1 [id^="campaign_groups_"]');
    }

    var total = 0;
    var selected = 0;

    groups.forEach((item, i) => {
      total += 1;
      if (item.checked == true) {
        selected += 1
      }
    });

    if (total > 0 && selected == total) {
      document.querySelector('#all_groups').checked = true
    } else {
      document.querySelector('#all_groups').checked = false
    }

    if (this.activeSearch()) {
      document.querySelectorAll('#table_2 [data-group-header][data-selected-query-result]').forEach((item, i) => {
        if (parseInt(item.getAttribute('data-selected-query-result')) > 0) {
          var group = item.getAttribute('data-group-header');
          var group_selected = document.querySelectorAll(`#table_2 [data-group-id="${group}"][data-query-result][data-selected]`).length;
          if (parseInt(item.getAttribute('data-selected-query-result')) == group_selected) {
            item.querySelector('.checkboxRG input').checked = true;
          } else {
            item.querySelector('.checkboxRG input').checked = false;
          }
        }
      });
      var users = document.querySelectorAll('#table_2 [data-query-result] [id^="campaign_group-users"]');
    } else {
      document.querySelectorAll('#table_2 [data-group-header]').forEach((item, i) => {
        var group = item.getAttribute('data-group-header');
        var group_selected = document.querySelectorAll(`#table_2 [data-group-id="${group}"][data-selected]`).length;
        if (parseInt(item.getAttribute('data-total-users')) == group_selected) {
          item.querySelector('.checkboxRG input').checked = true;
        } else {
          item.querySelector('.checkboxRG input').checked = false;
        }
      });
      var users = document.querySelectorAll('#table_2 [id^="campaign_group-users"]');
    }

    var total = 0;
    var selected = 0;

    users.forEach((item, i) => {
      total += 1;
      if (item.checked == true) {
        selected += 1
      }
    });

    if (total > 0 && selected == total) {
      document.querySelector('#all_groups_and_users').checked = true
    } else {
      document.querySelector('#all_groups_and_users').checked = false
    }
  }
}
