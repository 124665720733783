import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    update_badges(event) {
        const counts = event.detail;

        const unread_badge_element = document.getElementById('badge-unread');
        unread_badge_element.innerHTML = counts.unread;
        unread_badge_element.style.display = counts.unread === 0 ? 'none' : 'inline-block'

        const flagged_badge_element = document.getElementById('badge-flagged');
        flagged_badge_element.innerHTML = counts.flagged;
        flagged_badge_element.style.display = counts.flagged === 0 ? 'none' : 'inline-block'
    }
}
