import { SocketUserController } from '../socket_user_controller'

export class UserActionController extends SocketUserController {
    word_cloud = undefined;
    cloud_timer = undefined;
    previous_words = {}
    fill = d3.scale.category20();

    fill2(index) {
      let red = ['rgba(208, 1, 27, 1)', 'rgba(208, 1, 27, 0.8)', 'rgba(208, 1, 27, 0.6)', 'rgba(208, 1, 27, 0.4)', 'rgba(208, 1, 27, 0.2)'];
      let yellow = ['rgba(249, 202, 14, 1)', 'rgba(249, 202, 14, 0.8)', 'rgba(249, 202, 14, 0.6)', 'rgba(249, 202, 14, 0.4)', 'rgba(249, 202, 14, 0.3)'];
      let green = ['rgba(63, 145, 66, 1)', 'rgba(63, 145, 66, 0.8)', 'rgba(63, 145, 66, 0.6)', 'rgba(63, 145, 66, 0.4)', 'rgba(63, 145, 66, 0.2)'];
      let blue = ['rgba(65, 131, 196, 1)', 'rgba(65, 131, 196, 0.8)', 'rgba(65, 131, 196, 0.6)', 'rgba(65, 131, 196, 0.4)', 'rgba(65, 131, 196, 0.2)'];
      let darkblue = ['rgba(15, 50, 86, 1)', 'rgba(15, 50, 86, 0.8)', 'rgba(15, 50, 86, 0.6)', 'rgba(15, 50, 86, 0.4)', 'rgba(15, 50, 86, 0.2)'];
      let colors = red.concat(yellow, green, blue, darkblue);

      return colors[index];
    }

    hash(string) {
        let hash = 0, i, chr;
        if (string.length === 0) return hash;
        for (i = 0; i < string.length; i++) {
            chr = string[i];
            hash = ((hash << 5) - hash) + chr;
            hash |= 0;
        }
        return hash;
    };

    add_timer(time) {
        const panel = document.getElementsByClassName('panel-heading')[0]
        const timer_div = document.createElement('div')
        timer_div.classList.add('badge', 'lq-badge')
        timer_div.id = 'timer'
        timer_div.innerText = time
        timer_div.style.position = 'absolute'
        timer_div.style.right = '16px'
        panel.childNodes[1].insertAdjacentElement('beforebegin', timer_div)

        return timer_div;
    }

    enable_description_toggle() {
        const toggle = document.getElementById('question-header');
        const leader = document.getElementById('leaderboard-container');
        const desc = document.getElementById('description-container');

        if (toggle === null || leader === null || desc === null) {
            return;
        }

        toggle.onclick = function () {
            leader.hidden = !leader.hidden;
            desc.hidden = !desc.hidden;
        }
    }

    add_answer_overview() {
        const wrong_next = document.getElementById('next');
        if (wrong_next !== null && wrong_next !== undefined) {
            wrong_next.style.visibility = 'hidden';
        }
    }

    draw_word_cloud(cloud) {
        const that = this;
        cloud.enter()
            .append("text")
            .attr('class', 'lq-cloud-word')
            .style("fill", function (d, _) {
                return that.fill2(Array.from(d.text).map((c) => c.charCodeAt()).reduce((l, r) => l + r) % 20);
            })
            .attr("text-anchor", "middle")
            .attr('font-size', 1)
            .text(function (d) {
                return d.text;
            });

        cloud.transition()
            .duration(600)
            .style("font-size", function (d) {
                return d.size + "px";
            })
            .attr("transform", function (d) {
                return "translate(" + [d.x, d.y] + ")";
            })
            .style("fill-opacity", 1);

        cloud.exit()
            .transition()
            .duration(200)
            .style('fill-opacity', 1e-6)
            .attr('font-size', 1)
            .remove();
    }

    update_word_cloud(names, width, height, draw) {
        const words = names.map((u) => {
            return {text: u}
        })
        const new_words = {};
        const word_data = [];

        const previous_count = Object.keys(this.previous_words).length;
        let any_new = false;

        for (let i = 0; i < words.length; i++) {
            new_words[words[i].text] = true;
            const word = words[i];
            const new_word = !(this.previous_words[word.text] || previous_count === 0)
            any_new = any_new || new_word;
            word.size = new_word ? 90 : 45;
            word_data.push(word)
        }
        this.previous_words = new_words;

        d3.layout.cloud().size([width, height])
            .words(word_data)
            .rotate(0)
            .font("Impact")
            .fontSize((w) => w.size)
            .on("end", draw)
            .start();

        if (any_new) {
            const that = this;
            this.cloud_timer = setTimeout(function () {
                that.create_or_update_word_cloud(names)
            }, 1000)
        }
    }

    create_word_cloud(cloud_container) {
        const that = this;

        function wordCloud(selector) {
            const width = cloud_container.offsetWidth - 32;
            const height = cloud_container.offsetHeight - 32;

            const svg = d3.select(selector).append("svg")
                .attr("width", width)
                .attr("height", height)
                .style("margin-left", '16px')
                .style("margin-top", "16px")
                .append("g")
                .attr("transform", `translate(${width / 2},${height / 2})`);


            function draw(words) {
                const cloud = svg.selectAll("g text").data(words, function (d) {
                    return d.text;
                })
                that.draw_word_cloud(cloud)
            }

            return {
                update: (words) => {
                    that.update_word_cloud(words, width, height, draw)
                }
            }

        }

        this.word_cloud = wordCloud('#cloud-container');

        document.body.onresize = function (_) {
            const cloud_container = document.getElementById('cloud-container')

            if (cloud_container === undefined || cloud_container === null) {
                return
            }

            const svg = cloud_container.children[0];
            const width = cloud_container.offsetWidth - 32;
            const height = cloud_container.offsetHeight - 32;

            svg.setAttribute('width', width);
            svg.setAttribute('height', height);
        }
    }

    create_or_update_word_cloud(users) {
        if (this.cloud_timer !== undefined) {
            clearTimeout(this.cloud_timer)
        }

        const cloud_container = document.getElementById('cloud-container')
        if (d3.layout.cloud === undefined || cloud_container === undefined || cloud_container === null) {
            return
        }

        if (this.word_cloud === undefined) {
            this.create_word_cloud(cloud_container)
        }

        this.word_cloud.update(users)
    }

    post_script_styling() {
        const more_options = document.getElementById('more-options');
        const lqe_div = document.getElementById('lq-explanation');
        if (more_options !== undefined && more_options !== null && lqe_div !== undefined && lqe_div !== null) {
            more_options.style.marginBottom = '31px'
        }

        const answer_options = document.getElementsByClassName('q-answer-options');
        if (answer_options.length > 0 && lqe_div !== undefined && lqe_div !== null) {
            const old_height = answer_options[0].style.height;
            answer_options[0].style.height = `${parseInt(old_height) - 31}px`;
        }
    }

    add_more_options_handler() {
        const more_options = document.getElementById('more-options');
        const answer_options = document.getElementsByClassName('q-answer-options');
        if (answer_options.length > 0 && more_options !== undefined && more_options !== null) {
            more_options.onclick = (_) => {
                answer_options[0].scrollTop = parseInt(answer_options[0].style.height);
            }
        }
    }

    update_components() {
        const state = window['quiz_state']
        const join_url = document.querySelector('#live_quiz_url')

        this.swap_selected(state['index'])

        // Add client specific elements
        if (state['timer'] !== -1) {
            this.add_timer(state['timer'])
        }

        window['previous_users'] = {};
        if (state['state'] === 'participants') {
            const list = document.getElementById('participant-list').children;
            const names = [];

            for (let i = 0; i < list.length; i++) {
                names.push(list[i].children[0].innerHTML);
            }

            this.create_or_update_word_cloud(names);

            if (join_url !== undefined && join_url !== null) {
              join_url.style.display = 'block'
            }
        } else {
            this.word_cloud = undefined;

            if (join_url !== undefined && join_url !== null) {
              join_url.style.display = 'none'
            }
        }

        this.add_answer_overview()
        this.enable_description_toggle();

        this.run_scripts();

        this.add_more_options_handler();
    }

    // Swap the selected button to the given target
    swap_selected(index) {
        const previous = document.getElementsByClassName('active-page')[0]
        if (previous !== null && previous !== undefined) {
            previous.classList.remove('active-page')
            previous.classList.remove('btn-primary')
            previous.classList.add('btn-default')
        }

        let target;
        switch (window.quiz_state.state) {
            case 'participants':
                target = document.getElementById('start-screen-button');
                break;
            case 'podium':
                target = document.getElementById('podium-screen-button');
                break;
            default:
                target = document.getElementById('page-index-' + Math.floor(index));
        }
        if (target !== null && target !== undefined) {
            target.classList.remove('btn-default')
            target.classList.add('active-page')
            target.classList.add('btn-primary')
        }
    }

    swap_content(data) {
        window['quiz_state'] = data['quiz_state'];

        const container = document.getElementById('content-container')
        container.innerHTML = data['html']

        this.update_components()
    }

    fetch_partial(url) {
        return fetch(url)
            .then(response => response.text())
            .then(data => {
                try {
                    return JSON.parse(data.toString())
                } catch (e) {
                    console.log(url)
                    console.log(data.toString())
                    return '<div>An error occurred during question receive</div>'
                }
            })
    }

    // Swap the question being displayed to the given question
    update_content() {
        const session = window['quiz_state']['session'];
        let base_url = window.location.href.split('?')[0]

        const match = base_url.match(/live_quizzes\/\d+/);
        if (match === null) {
            return false;
        }

        const url = `${base_url}/content?lq_session=${session}`

        return this.fetch_partial(url).then(data => this.swap_content(data))
    }

    // Update the list in participant screen to contain all current usernames
    update_user_list(users) {
        const ul = document.getElementById('participant-list');

        if (ul !== null && ul !== undefined) {
            ul.innerHTML = '';
            for (let i = 0; i < users.length; i++) {
                const li = document.createElement('li');
                const h4 = document.createElement('h4');
                h4.innerHTML = users[i];
                li.appendChild(h4);
                ul.appendChild(li);
            }
        }

        this.create_or_update_word_cloud(users)
    }

    run_scripts() {
        redgrasp.lq_explanation_trigger()
        redgrasp.comment_trigger()
        redgrasp.moreOptions()
        redgrasp.optionScroll()

        this.post_script_styling();
    }

    on_data(data) {
        switch (data['action']) {
            case 'log':
                console.log(data['message'])
                break
            case 'users':
                this.update_user_list(data['users'])
                break
            case 'select':
                this.update_content();
                break
            default:
                super.on_data(data)
        }
    }

    on_connect() {
        super.on_connect()
        console.log('Connection successful')
    }

    on_disconnect() {
        console.log('Socket closed')
    }

    transmit(action, parameters) {
        const params = parameters !== undefined ? parameters : {}

        if (window['quiz_state'] !== undefined && window['quiz_state']['session'] !== undefined) {
            params['lq_session'] = window['quiz_state']['session'];
        }

        super.transmit(action, params);
    }

    // Startup method that connects the page to the socket channel
    connect() {
        d3.layout.cloud = cloud;
        const session = window['quiz_state']['session']

        this.create_socket_connection('LiveQuizChannel', {lq_session: session})

        this.update_components()
    }

    disconnect() {
        const session = window['quiz_state']['session'];
        fetch(`${this.data.get('disconnect-url')}?lq_session=${session}`)
    }
}

export default UserActionController;
