import { UserActionController } from './user_action_controller'

export default class extends UserActionController {
    timer_delta = 0;
    current_time = 0;

    user_count = 0;
    answer_count = 0;

    handle_time_update(new_time, timer_div) {
        timer_div.innerHTML = new_time < 0 ? 0 : new_time;
        if (new_time <= 0) {
            clearInterval(window['quiz_timer'])

            this.select("next");
        } else if (new_time !== this.time) {
            this.current_time = new_time

            this.transmit('timer', {time_left: this.current_time})
        }
    }

    start_timer(timer_div, time) {
        this.current_time = time;
        this.timer_delta = 1;

        const that = this;

        clearInterval(window['quiz_timer'])
        window['quiz_timer'] = setInterval(() => {
            const new_time = that.current_time - that.timer_delta;

            that.handle_time_update(new_time, timer_div)
        }, 1000)

        timer_div.onclick = (_) => {
            that.timer_delta = that.timer_delta === 0 ? 1 : 0;
        }
    }

    kill_timers() {
        clearInterval(window['quiz_timer'])
    }

    add_timer_incrementer(timer_div) {
        const time_add_div = document.createElement('div')
        time_add_div.classList.add('badge', 'lq-badge', 'lq-badge-hover')
        time_add_div.innerText = '+'
        time_add_div.style.position = 'absolute'
        time_add_div.style.right = '16px'
        time_add_div.style.minWidth = '21px'
        time_add_div.id = 'time-incrementer'
        timer_div.insertAdjacentElement('afterend', time_add_div);

        const that = this;
        time_add_div.onclick = (_) => {
            that.handle_time_update(that.current_time + 5, timer_div)
        }
    }

    add_timer_decrementer(timer_div) {
        const time_add_div = document.createElement('div')
        time_add_div.classList.add('badge', 'lq-badge', 'lq-badge-hover')
        time_add_div.innerText = '\u2013'
        time_add_div.style.position = 'absolute'
        time_add_div.style.right = '101px'
        time_add_div.style.minWidth = '21px'
        time_add_div.id = 'time-incrementer'
        timer_div.insertAdjacentElement('beforebegin', time_add_div);

        const that = this;
        time_add_div.onclick = (_) => {
            that.handle_time_update(that.current_time - 5, timer_div)
        }
    }

    add_timer(time) {
        const timer_div = super.add_timer(time)
        timer_div.classList.add('lq-badge-hover')
        timer_div.style.right = '50px'

        this.add_timer_incrementer(timer_div)
        this.add_timer_decrementer(timer_div)

        this.start_timer(timer_div, time)
        return timer_div;
    }

    add_user_count() {
        let user_div = document.getElementById('user-count');
        if (user_div === null || user_div === undefined) {
            const panel = document.getElementsByClassName('panel-heading')[0]
            user_div = document.createElement('div')
            user_div.id = 'user-count'
            panel.childNodes[1].insertAdjacentElement('beforebegin', user_div)
        }

        user_div.style.position = 'absolute'
        user_div.style.left = '16px'
        user_div.classList.add('badge', 'lq-badge')
        user_div.innerHTML = '0/' + this.user_count
    }

    update_user_count(data) {
        this.user_count = data['user_count']
        const user_div = document.getElementById('user-count')

        if (user_div !== null && user_div !== undefined) {
            const parts = user_div.innerHTML.split('/')
            user_div.innerHTML = parts[0] + '/' + this.user_count
        }

        this.update_user_list(data['users'])
    }

    update_answer_count(count) {
        this.answer_count = count
        const user_div = document.getElementById('user-count')

        if (user_div !== null && user_div !== undefined) {
            const parts = user_div.innerHTML.split('/')
            user_div.innerHTML = count + '/' + parts[1]
        }
    }

    replace_button(button, enabled, func, callback) {
        if (enabled) {
            button.classList.remove('disabled')
        } else {
            button.classList.add('disabled')
        }

        // Expose function to window scope
        window[func] = callback;

        button.childNodes.forEach((node) => {
            if (node.tagName === 'A') {
                node.href = "javascript:" + func + "(this)";
            }
        })
    }

    replace_nav_buttons(direction) {
        const state = window['quiz_state'];

        const buttons = Array.from(document.getElementsByClassName(direction));

        const enabled = state[direction];

        const that = this;

        const func = function (_) {
            enabled ? that.select(direction) : console.log(`No ${direction} page`);
        }

        buttons
            .filter(b => !(b === undefined || b === null))
            .forEach(b => that.replace_button(b, state[direction], `${direction}_func`, func))
    }

    update_components() {
        super.update_components();
        this.replace_nav_buttons('next');
        this.replace_nav_buttons('previous');

        if (window['quiz_state']['state'] === 'question') {
            this.add_user_count();
        }

        if (window['quiz_state']['state'] === 'participants') {
            document.getElementById('url-container').classList.remove('hidden-url-container')
        } else {
            document.getElementById('url-container').classList.add('hidden-url-container')
        }
    }

    update_content(index) {
        const that = this
        super.update_content(index).then(_ => that.get_user_and_answer_count())
    }

    closeFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
    }

    openFullscreen(elem) {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    }

    connect() {
        super.connect()

        this.replace_nav_buttons('next')
        this.replace_nav_buttons('previous')

        const that = this
        window['select_func'] = function (index, state) {
            that.select("select", index, state);
        }

        const present_button = document.getElementById('present-button')

        if (present_button) {
            present_button.onclick = (_) => {
                const root_url = window.location.href.split('?')[0];
                window.open(root_url + '/present', 'presenter view', 'fullscreen: yes')
            }
        }

        let fullscreen = false;
        const fullscreen_button = document.getElementById('fullscreen-button');

        fullscreen_button.onclick = (_) => {

            if (fullscreen) {
                that.closeFullscreen()
                fullscreen_button.innerHTML.replaceAll('Exit Fullscreen', 'Fullscreen');
                fullscreen = false;
            } else {
                that.openFullscreen(document.documentElement);
                fullscreen_button.innerHTML.replaceAll('Fullscreen', 'Exit Fullscreen');
                fullscreen = true;
            }

        }
    }

    // Select a page
    select(event, index, state) {
        this.kill_timers()

        this.transmit("select", {event: event, index: index, state: state})
    }

    swap_selected(index) {
        super.swap_selected(index);

        const buttons = Array.prototype.slice.call(document.getElementsByClassName('btn'))
            .filter((i) => i.id.indexOf('page-index') !== -1)

        const max = buttons.map((i) => parseInt(i.id.split('-')[2])).reduce((l, r) => l < r ? r : l)

        const left = index - 3 >= 0 ? index - 3 : 0;
        const right = index + 3 <= max ? index + 3 : max;

        for (let i = 1; i < max; i++) {
            if (i >= left && i <= right) {
                buttons[i].style.display = '';
            } else {
                buttons[i].style.display = 'none';
            }
        }
    }

    get_user_and_answer_count() {
        this.transmit('counts', {session: window.lq_session})
    }

    on_connect() {
        super.on_connect();
        this.get_user_and_answer_count();
    }

    on_data(data) {
        switch (data['action']) {
            case 'users':
                this.update_user_count(data)
                break
            case 'answers':
                this.update_answer_count(data['answer_count']);
                break
            default:
                super.on_data(data);
        }
    }

    disconnect() {
        this.kill_timers();
        super.disconnect();
    }
}
