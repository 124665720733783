import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    show_relevant(unordered_list) {
        const children = Array.prototype.slice.call(unordered_list.children);

        const values = {};

        children.forEach((i) => {
            const val = i.children[0].value
            if (val !== '') {
                values[val] = true;
            }
        })

        const options = document.getElementsByTagName('option')

        for (let i = 0; i < options.length; i++) {
            const val = options[i].value;
            const parent_val = options[i].parentNode.value;

            options[i].style.display = values[val] && val !== parent_val ? 'none' : '';
        }
    }

    change_function(unordered_list) {
        const children = unordered_list.children;

        let blank_found = false;
        const remove_array = [];

        for (let i = children.length - 1; i >= 0; i--) {
            if (children[i].children[0].value === '') {
                if (blank_found) {
                    remove_array.push(children[i])
                } else {
                    blank_found = true;
                }
            }
        }

        if (!blank_found) {
            const clone = children[0].cloneNode(true)
            children[children.length - 1].insertAdjacentElement('afterend', clone);
            clone.children[0].value = '';
            this.attach_listener(clone)
        }

        remove_array.forEach((i) => unordered_list.removeChild(i))

        this.show_relevant(unordered_list);
    }

    attach_listener(list_item) {
        const that = this;
        const child = list_item.childNodes[0];

        // if (child.name.indexOf('[]') === -1) {
        //     child.name = child.name + '[]';
        // }

        child.onchange = (_) => {
            that.change_function(list_item.parentNode);
        }
    }

    connect() {
        const that = this;
        const list = document.getElementsByClassName('host-select')
        list.forEach((i) => that.attach_listener(i))
        this.show_relevant(list[0].parentNode);
    }
}
