import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    update_icon(event) {
        const counts = event.detail;

        document.getElementById('new-notifications').style.display = counts.comments === 0 ? 'none' : 'block';
        document.getElementById('new-borrow-notifications').style.display = counts.borrowed === 0 ? 'none' : 'block';
    }
}
