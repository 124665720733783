import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  filter(event) {
    var period = event.target.querySelector('input').value;
    var url = window.location.href;
    var params = url.split('?').pop();
    if (params.match(/page|org_unit|admin_scope/)) {
      url = url.split('?').shift().concat('/' + this.data.get('url') + '?' + params.concat('&period=' + period));
    } else {
      url = url.concat('/' + this.data.get('url') + '?period=' + period);
    }
    window.location.replace(url);
  }
}
