import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['reset'];
  static values = {
    answerId: Number,
    color: String,
    url: String
  }

  grade(event) {
    const that = this;
    const grade = event.target.querySelector('input').value;
    const color = event.target.getAttribute('data-color');
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    fetch(this.urlValue, {
      method: "PUT",
      body: JSON.stringify({
        answer: this.answerIdValue,
        grade: grade
      }),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "X-CSRF-Token": token
      }
    })
    .then(response => response.json())
    .then(data => {
      document.querySelector('#awarded-grade circle').style.stroke = color
      document.querySelector('#awarded-grade circle').style.fill = color
      that.resetTarget.removeAttribute('disabled')
    })
  }

  reset() {
    const that = this;
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    if (!this.resetTarget.hasAttribute('disabled')) {
      fetch(this.urlValue, {
        method: "DELETE",
        body: JSON.stringify({
          answer: this.answerIdValue
        }),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "X-CSRF-Token": token
        }
      })
      .then(response => response.json())
      .then(data => {
        document.querySelectorAll('input[name="grade"]').forEach((item, i) => {
          item.checked = false;
          item.parentElement.classList.remove('active');

        });
        that.resetTarget.setAttribute('disabled', '')
        document.querySelector('#awarded-grade circle').style.stroke = '#ccc'
        document.querySelector('#awarded-grade circle').style.fill = '#ccc'
      })
    }
  }
}
